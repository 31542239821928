/* eslint-disable */
import React from 'react';
import { FormInput } from "../../../components";

function CreditRow({ number, pmEval }) {

    const pmEvalDocument = eval("pmEval?.evaluationOfCreditHistory?.evaluationOfCreditHistoryP" + `${number}` + "document");
    const pmEvalSchedule = eval("pmEval?.evaluationOfCreditHistory?.evaluationOfCreditHistoryP" + `${number}` + "schedule");
    const pmEvalPayment = eval("pmEval?.evaluationOfCreditHistory?.evaluationOfCreditHistoryP" + `${number}` + "payment");
    const pmEvalMissed = eval("pmEval?.evaluationOfCreditHistory?.evaluationOfCreditHistoryP" + `${number}` + "missed");


    return (
        <tr className="px-4 py-6 border-b hover:bg-gray-100">
            <td className='px-4 py-3'>{number}</td>
            <td className='px-4 py-3'>
                <FormInput 
                    type="text" 
                    name={`evaluationOfCreditHistoryP${number}document`} 
                    id={`evaluationOfCreditHistoryP${number}document`} 
                    value={pmEval && pmEvalDocument}
                    disabled={pmEval ? true : false}
                />
            </td>
            <td className='px-4 py-3'>
                <FormInput 
                    type="text" 
                    name={`evaluationOfCreditHistoryP${number}schedule`} 
                    id={`evaluationOfCreditHistoryP${number}schedule`}
                    value={pmEval && pmEvalSchedule}
                    disabled={pmEval ? true : false}
                />
            </td>
            <td className='px-4 py-3'>
                <FormInput 
                    type="text" 
                    name={`evaluationOfCreditHistoryP${number}payment`} 
                    id={`evaluationOfCreditHistoryP${number}payment`}
                    value={pmEval && pmEvalPayment}
                    disabled={pmEval ? true : false}
                />
            </td>
            <td className='px-4 py-3'>
                <FormInput 
                    type="text" 
                    name={`evaluationOfCreditHistoryP${number}missed`} 
                    id={`evaluationOfCreditHistoryP${number}missed`}
                    value={pmEval && pmEvalMissed}
                    disabled={pmEval ? true : false}
                />
            </td>
        </tr>
    )
}

export default CreditRow