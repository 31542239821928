const countries = [
    {
      "name": "AFGHANISTAN",
      "code": "AFG",
    },
    {
      "name": "ALBANIA",
      "code": "ALB",
    },
    {
      "name": "ALGERIA",
      "code": "DZA",
    },
    {
      "name": "AMERICAN SAMOA",
      "code": "ASM",
    },
    {
      "name": "ANDORRA",
      "code": "AND",
    },
    {
      "name": "ANGOLA",
      "code": "AGO",
    },
    {
      "name": "ANGUILLA",
      "code": "AIA",
    },
    {
      "name": "ANTARCTICA",
      "code": "ATA",
    },
    {
      "name": "ANTIGUA AND BARBUDA",
      "code": "ATG",
    },
    {
      "name": "ARGENTINA",
      "code": "ARG",
    },
    {
      "name": "ARMENIA",
      "code": "ARM",
    },
    {
      "name": "ARUBA",
      "code": "ABW",
    },
    {
      "name": "AUSTRALIA",
      "code": "AUS",
    },
    {
      "name": "AUSTRIA",
      "code": "AUT",
    },
    {
      "name": "AZERBAIJAN",
      "code": "AZE",
    },
  
    {
      "name": "BAHAMAS",
      "code": "BHS",
    },
    {
      "name": "BAHRAIN",
      "code": "BHR",
    },
    {
      "name": "BANGLADESH",
      "code": "BGD",
    },
    {
      "name": "BARBADOS",
      "code": "BRB",
    },
    {
      "name": "BELARUS",
      "code": "BLR",
    },
    {
      "name": "BELGIUM",
      "code": "BEL",
    },
    {
      "name": "BELIZE",
      "code": "BLZ",
    },
    {
      "name": "BENIN",
      "code": "BEN",
    },
    {
      "name": "BERMUDA",
      "code": "BMU",
    },
    {
      "name": "BHUTAN",
      "code": "BTN",
    },
    {
      "name": "BOLIVIA",
      "code": "BOL",
    },
    {
      "name": "BOSNIA AND HERZEGOWINA",
      "code": "BIH",
    },
    {
      "name": "BOTSWANA",
      "code": "BWA",
    },
    {
      "name": "BOUVET ISLAND",
      "code": "BVT",
    },
    {
      "name": "BRAZIL",
      "code": "BRA",
    },
    {
      "name": "BRIT. INDIAN OCEAN TERRITORY",
      "code": "IOT",
    },
    {
      "name": "BRUNEI DARUSSALAM",
      "code": "BRN",
    },
    {
      "name": "BULGARIA",
      "code": "BGR",
    },
    {
      "name": "BURKINA FASO",
      "code": "BFA",
    },
    {
      "name": "BURUNDI",
      "code": "BDI",
    },
  
    {
      "name": "CAMBODIA",
      "code": "KHM",
    },
    {
      "name": "CAMEROON",
      "code": "CMR",
    },
    {
      "name": "CANADA",
      "code": "CAN",
    },
    {
      "name": "CAPE VERDE",
      "code": "CPV",
    },
    {
      "name": "CAYMAN ISLANDS",
      "code": "CYM",
    },
    {
      "name": "CENTRAL AFRICAN REPUBLIC",
      "code": "CAF",
    },
    {
      "name": "CHAD",
      "code": "TCD",
    },
    {
      "name": "CHILE",
      "code": "CHL",
    },
    {
      "name": "CHINA",
      "code": "CHN",
    },
    {
      "name": "CHRISTMAS ISLAND",
      "code": "CXR",
    },
    {
      "name": "COCOS (KEELING) ISLANDS",
      "code": "CCK",
    },
    {
      "name": "COLOMBIA",
      "code": "COL",
    },
    {
      "name": "COMOROS",
      "code": "COM",
    },
    {
      "name": "CONGO",
      "code": "COG",
    },
    {
      "name": "COOK ISLANDS",
      "code": "COK",
    },
    {
      "name": "COSTA RICA",
      "code": "CRI",
    },
    {
      "name": "COTE D'IVOIRE",
      "code": "CIV",
    },
    {
      "name": "CROATIA",
      "code": "HRV",
    },
    {
      "name": "CUBA",
      "code": "CUB",
    },
    {
      "name": "CYPRUS",
      "code": "CYP",
    },
    {
      "name": "CZECH REPUBLIC",
      "code": "CZE",
    },
  
    {
      "name": "DENMARK",
      "code": "DNK",
    },
    {
      "name": "DJIBOUTI",
      "code": "DJI",
    },
    {
      "name": "DOMINICA",
      "code": "DMA",
    },
    {
      "name": "DOMINICAN REPUBLIC",
      "code": "DOM",
    },
  
    {
      "name": "EAST TIMOR",
      "code": "TMP",
    },
    {
      "name": "ECUADOR",
      "code": "ECU",
    },
    {
      "name": "EGYPT",
      "code": "EGY",
    },
    {
      "name": "EL SALVADOR",
      "code": "SLV",
    },
    {
      "name": "EQUATORIAL GUINEA",
      "code": "GNQ",
    },
    {
      "name": "ERITREA",
      "code": "ERI",
    },
    {
      "name": "ESTONIA",
      "code": "EST",
    },
    {
      "name": "ETHIOPIA",
      "code": "ETH",
    },
  
    {
      "name": "FALKLAND ISLANDS (MALVINAS)",
      "code": "FLK",
    },
    {
      "name": "FAEROE ISLANDS",
      "code": "FRO",
    },
    {
      "name": "FIJI",
      "code": "FJI",
    },
    {
      "name": "FINLAND",
      "code": "FIN",
    },
    {
      "name": "FRANCE",
      "code": "FRA",
    },
    {
      "name": "FRENCH GUIANA",
      "code": "GUF",
    },
    {
      "name": "FRENCH POLYNESIA",
      "code": "PYF",
    },
    {
      "name": "FRENCH SOUTHERN TERRITOR.",
      "code": "ATF",
    },
  
    {
      "name": "GABON",
      "code": "GAB",
    },
    {
      "name": "GAMBIA",
      "code": "GMB",
    },
    {
      "name": "GEORGIA",
      "code": "GEO",
    },
    {
      "name": "GERMANY",
      "code": "DEU",
    },
    {
      "name": "GHANA",
      "code": "GHA",
    },
    {
      "name": "GIBRALTAR",
      "code": "GIB",
    },
    {
      "name": "GREECE",
      "code": "GRC",
    },
    {
      "name": "GREENLAND",
      "code": "GRL",
    },
    {
      "name": "GRENADA",
      "code": "GRD",
    },
    {
      "name": "GUADELOUPE",
      "code": "GLP",
    },
    {
      "name": "GUAM",
      "code": "GUM",
    },
    {
      "name": "GUATEMALA",
      "code": "GTM",
    },
    {
      "name": "GUINEA",
      "code": "GIN",
    },
    {
      "name": "GUINEA-BISSAU",
      "code": "GNB",
    },
    {
      "name": "GUYANA",
      "code": "GUY",
    },
  
    {
      "name": "HAITI",
      "code": "HTI",
    },
    {
      "name": "HEARD AND MC DONALD ISLANDS",
      "code": "HMD",
    },
    {
      "name": "HONDURAS",
      "code": "HND",
    },
    {
      "name": "HONG KONG",
      "code": "HKG",
    },
    {
      "name": "HUNGARY",
      "code": "HUN",
    },
  
    {
      "name": "ICELAND",
      "code": "ISL",
    },
    {
      "name": "INDIA",
      "code": "IND",
    },
    {
      "name": "INDONESIA",
      "code": "IDN",
    },
    {
      "name": "IRAN",
      "code": "IRN",
    },
    {
      "name": "IRAQ",
      "code": "IRQ",
    },
    {
      "name": "IRELAND",
      "code": "IRL",
    },
    {
      "name": "ISRAEL",
      "code": "ISR",
    },
    {
      "name": "ITALY",
      "code": "ITA",
    },
  
    {
      "name": "JAMAICA",
      "code": "JAM",
    },
    {
      "name": "JAPAN",
      "code": "JPN",
    },
    {
      "name": "JORDAN",
      "code": "JOR",
    },
  
    {
      "name": "KAZAKHSTAN",
      "code": "KAZ",
    },
    {
      "name": "KENYA",
      "code": "KEN",
    },
    {
      "name": "KIRIBATI",
      "code": "KIR",
    },
    {
      "name": "KUWAIT",
      "code": "KWT",
    },
    {
      "name": "KYRGYZSTAN",
      "code": "KGZ",
    },
  
    {
      "name": "LAO PEOPLE'S DEMOCRATIC REP.",
      "code": "LAO",
    },
    {
      "name": "LATVIA",
      "code": "LVA",
    },
    {
      "name": "LEBANON",
      "code": "LBN",
    },
    {
      "name": "LESOTHO",
      "code": "LSO",
    },
    {
      "name": "LIBERIA",
      "code": "LBR",
    },
    {
      "name": "LIBYAN ARAB JAMAHIRIYA",
      "code": "LBY",
    },
    {
      "name": "LIECHTENSTEIN",
      "code": "LIE",
    },
    {
      "name": "LITHUANIA",
      "code": "LTU",
    },
    {
      "name": "LUXEMBOURG",
      "code": "LUX",
    },
  
    {
      "name": "MACAU",
      "code": "MAC",
    },
    {
      "name": "MACEDONIA",
      "code": "MKD",
    },
    {
      "name": "MADAGASCAR",
      "code": "MDG",
    },
    {
      "name": "MALAWI",
      "code": "MWI",
    },
    {
      "name": "MALAYSIA",
      "code": "MYS",
    },
    {
      "name": "MALDIVES",
      "code": "MDV",
    },
    {
      "name": "MALI",
      "code": "MLI",
    },
    {
      "name": "MALTA",
      "code": "MLT",
    },
    {
      "name": "MARSHALL ISLANDS",
      "code": "MHL",
    },
    {
      "name": "MARTINIQUE",
      "code": "MTQ",
    },
    {
      "name": "MAURITANIA",
      "code": "MRT",
    },
    {
      "name": "MAURITIUS",
      "code": "MUS",
    },
    {
      "name": "MEXICO",
      "code": "MEX",
    },
    {
      "name": "MICRONESIA  (FED. STATES OF)",
      "code": "FSM",
    },
    {
      "name": "MOLDOVA, REPUBLIC OF",
      "code": "MDA",
    },
    {
      "name": "MONACO",
      "code": "MCO",
    },
    {
      "name": "MONGOLIA",
      "code": "MNG",
    },
    {
      "name": "MONTSERRAT",
      "code": "MSR",
    },
    {
      "name": "MOROCCO",
      "code": "MAR",
    },
    {
      "name": "MOZAMBIQUE",
      "code": "MOZ",
    },
    {
      "name": "MYANMAR",
      "code": "MMR",
    },
  
    {
      "name": "NAMIBIA",
      "code": "NAM",
    },
    {
      "name": "NAURU",
      "code": "NRU",
    },
    {
      "name": "NEPAL",
      "code": "NPL",
    },
    {
      "name": "NETHERLANDS",
      "code": "NLD",
    },
    {
      "name": "NETHERLANDS ANTILLES",
      "code": "ANT",
    },
    {
      "name": "NEW CALEDONIA",
      "code": "NCL",
    },
    {
      "name": "NEW ZEALAND",
      "code": "NZL",
    },
    {
      "name": "NICARAGUA",
      "code": "NIC",
    },
    {
      "name": "NIGER",
      "code": "NER",
    },
    {
      "name": "NIGERIA",
      "code": "NGA",
    },
    {
      "name": "NIUE",
      "code": "NIU",
    },
    {
      "name": "NORFOLK ISLAND",
      "code": "NFK",
    },
    {
      "name": "NORTHERN MARIANA ISLANDS",
      "code": "MNP",
    },
    {
      "name": "NORWAY",
      "code": "NOR",
    },
  
    {
      "name": "OMAN",
      "code": "OMN",
    },
  
    {
      "name": "PAKISTAN",
      "code": "PAK",
    },
    {
      "name": "PALAU",
      "code": "PLW",
    },
    {
      "name": "PANAMA",
      "code": "PAN",
    },
    {
      "name": "PAPUA NEW GUINEA",
      "code": "PNG",
    },
    {
      "name": "PARAGUAY",
      "code": "PRY",
    },
    {
      "name": "PERU",
      "code": "PER",
    },
    {
      "name": "PHILIPPINES",
      "code": "PHL",
    },
    {
      "name": "PITCAIRN",
      "code": "PCN",
    },
    {
      "name": "POLAND",
      "code": "POL",
    },
    {
      "name": "PORTUGAL",
      "code": "PRT",
    },
    {
      "name": "PUERTO RICO",
      "code": "PRI",
    },
  
    {
      "name": "QATAR",
      "code": "QAT",
    },
  
    {
      "name": "REUNION",
      "code": "REU",
    },
    {
      "name": "ROMANIA",
      "code": "ROM",
    },
    {
      "name": "RUSSIAN FEDERATION",
      "code": "RUS",
    },
    {
      "name": "RWANDA",
      "code": "RWA",
    },
  
    {
      "name": "ST. HELENA",
      "code": "SHN",
    },
    {
      "name": "SAINT KITTS AND NEVIS",
      "code": "KNA",
    },
    {
      "name": "SAINT LUCIA",
      "code": "LCA",
    },
    {
      "name": "ST. PIERRE AND MIQUELON",
      "code": "SPM",
    },
    {
      "name": "SAINT VINCENT / GRENADINES",
      "code": "VCT",
    },
    {
      "name": "SAMOA WESTERN",
      "code": "WSM",
    },
    {
      "name": "SAN MARINO",
      "code": "SMR",
    },
    {
      "name": "SAO TOME AND PRINCIPE",
      "code": "STP",
    },
    {
      "name": "SAUDI ARABIA",
      "code": "SAU",
    },
    {
      "name": "SENEGAL",
      "code": "SEN",
    },
    {
      "name": "SEYCHELLES",
      "code": "SYC",
    },
    {
      "name": "SIERRA LEONE",
      "code": "SLE",
    },
    {
      "name": "SINGAPORE",
      "code": "SGP",
    },
    {
      "name": "SLOVAKIA",
      "code": "SVK",
    },
    {
      "name": "SLOVENIA",
      "code": "SVN",
    },
    {
      "name": "SOLOMON ISLANDS",
      "code": "SLB",
    },
    {
      "name": "SOMALIA",
      "code": "SOM",
    },
    {
      "name": "SOUTH AFRICA",
      "code": "ZAF",
    },
    {
      "name": "SPAIN",
      "code": "ESP",
    },
    {
      "name": "SRI LANKA",
      "code": "LKA",
    },
    {
      "name": "SUDAN",
      "code": "SDN",
    },
    {
      "name": "SURINAME",
      "code": "SUR",
    },
    {
      "name": "SVALBARD / JAN MAYEN IS.",
      "code": "SJM",
    },
    {
      "name": "SWAZILAND",
      "code": "SWZ",
    },
    {
      "name": "SWEDEN",
      "code": "SWE",
    },
    {
      "name": "SWITZERLAND",
      "code": "CHE",
    },
    {
      "name": "SYRIAN ARAB REPUBLIC",
      "code": "SYR",
    },
  
    {
      "name": "TAIWAN, PROVINCE OF CHINA",
      "code": "TWN",
    },
    {
      "name": "TAJIKISTAN",
      "code": "TJK",
    },
    {
      "name": "TANZANIA, UNITED REPUBLIC OF",
      "code": "TZA",
    },
    {
      "name": "THAILAND",
      "code": "THA",
    },
    {
      "name": "TOGO",
      "code": "TGO",
    },
    {
      "name": "TOKELAU",
      "code": "TKL",
    },
    {
      "name": "TONGA",
      "code": "TON",
    },
    {
      "name": "TRINIDAD AND TOBAGO",
      "code": "TTO",
    },
    {
      "name": "TUNISIA",
      "code": "TUN",
    },
    {
      "name": "TURKEY",
      "code": "TUR",
    },
    {
      "name": "TURKMENISTAN",
      "code": "TKM",
    },
    {
      "name": "TURKS AND CAICOS ISLANDS",
      "code": "TCA",
    },
    {
      "name": "TUVALU",
      "code": "TUV",
    },
  
    {
      "name": "UGANDA",
      "code": "UGA",
    },
    {
      "name": "UKRAINE",
      "code": "UKR",
    },
    {
      "name": "UNITED ARAB EMIRATES",
      "code": "ARE",
    },
    {
      "name": "UNITED KINGDOM",
      "code": "GBR",
    },
    {
      "name": "UNITED STATES",
      "code": "USA",
    },
    {
      "name": "UNITED STATES MIN. OUTL. ISLS.",
      "code": "UMI",
    },
    {
      "name": "URUGUAY",
      "code": "URY",
    },
    {
      "name": "UZBEKISTAN",
      "code": "UZB",
    },
  
    {
      "name": "VANUATU",
      "code": "VUT",
    },
    {
      "name": "VATICAN CITY STATE (HOLY SEE)",
      "code": "BAT",
    },
    {
      "name": "VENEZUELA",
      "code": "VEN",
    },
    {
      "name": "VIET NAM",
      "code": "VNM",
    },
    {
      "name": "VIRGIN ISLANDS (BRITISH)",
      "code": "VGB",
    },
    {
      "name": "VIRGIN ISLANDS (U.S.)",
      "code": "VIR",
    },
  
    {
      "name": "WALLIS AND FUTUNA ISLANDS",
      "code": "WLF",
    },
    {
      "name": "WESTERN SAHARA",
      "code": "ESH",
    },
  
    {
      "name": "YEMEN",
      "code": "YEM",
    },
    {
      "name": "YUGOSLAVIA",
      "code": "YUG",
    },
  
    {
      "name": "ZAIRE",
      "code": "ZAR",
    },
    {
      "name": "ZAMBIA",
      "code": "ZMB",
    },
    {
      "name": "ZIMBABWE",
      "code": "ZWE"
    },
]

export { countries }