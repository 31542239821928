import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken, selectStaffId } from '../../../slices/staffSlice';
import { Topbar, Sidebar, Heading, Loader, StaffAlias, Deactivate } from "../../../components";
import { fetchRequests } from "../../../Requests/getRequests";
import { authStaff } from '../../../utils/constants';
import { show } from '../../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Edit() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const staffId = useSelector(selectStaffId);
    const [staff, setStaff] = useState();

    useEffect(() => {
        const getUserAndRoles = async(accessToken, staffId) => {
            let staffUrl = `/auth/staff/find`;
            let headers = authStaff(accessToken);
            let params = {
                _id : staffId
            }

            let staffRes = await fetchRequests(staffUrl, "GET", headers, params, {});
            if(staffRes?.code === 401){
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            } else {
                setStaff(staffRes);
            }
        }

        getUserAndRoles(accessToken, staffId);
    },[accessToken, staffId, dispatch, navigate])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            {staff ? 
            <main className='main-content'>
                <section className='section-container'>
                    <div className='flex items-center justify-between mb-4'>
                        <Heading 
                            text={`Edit ${staff?.otherNames} ${staff?.surname}`}
                            size="text-lg mb-3 md:text-3xl font-bold tracking-tight text-gray-700"
                        />

                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
                        <div className="card">
                            <div className='mb-3'>
                                <Heading 
                                    text={"Edit Information"}
                                    size="text-base font-medium tracking-tight text-gray-700"
                                />

                                <StaffAlias 
                                    staff={staff}
                                    setStaff={setStaff}
                                />
                            </div>
                        </div>

                        {/* <div className='card'>
                            <div className='mb-3'>
                                <Heading 
                                    text={"Update Password"}
                                    size="text-base font-medium tracking-tight text-gray-700"
                                />
                            </div>

                            <ChangePass />
                        </div> */}

                        <div className="card">
                            <div className='mb-3'>
                                <Heading 
                                    text={"Approve Staff"}
                                    size="text-base font-medium tracking-tight text-gray-700"
                                />
                            </div>

                            <Deactivate 
                                _id={staff?._id}
                                approved={staff?.approved}
                            />
                        </div>
                    </div>

                </section>
            </main>
            : 
            <main className='p-4 md:px-8 md:py-6 space-y-6'>
                <Loader 
                    color="text-gray-500"
                    size="w-5 h-5"
                />
            </main>
            }

        </div>
    )
}

export default Edit