import { useState } from 'react';
import { Topbar, Sidebar, Heading } from "../../../components";
import DateFilter from './DateFilter';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { show } from '../../../slices/toastSlice';
import { fetchRequests } from '../../../Requests/getRequests';
import { dateToHumanFormatShort } from '../../../utils/constants';

function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [processing, setProcessing] = useState(false);
    const [payments, setPayments] = useState([]);

    const filterPayments = async(e) => {
        e.preventDefault();
        console.log(`Filtering payments for ${startDate} to ${endDate}...`);
        setProcessing(true);

        let url = `/payments/staff/filter-payments`;
        let headers = {
            'Content-Type': 'application/json',
            'authorization': `${accessToken}`
        }
        let params = {
            startDate,
            endDate
        }

        let res = await fetchRequests(url, "GET", headers, params, {});
        setProcessing(false);

        if(res?.code === 401){
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        // alert success
        dispatch(show({
            type : "success",
            state : true,
            message : `Payments updated successfully`
        }));

        setPayments(res);
    }

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className="section-container">
                    <div className='flex items-center justify-between mb-3'>
                        <Heading
                            text="Recent Payments"
                            size="text-3xl font-medium leading-6 text-display mb-5"
                            color="text-gray-700"
                        />

                        <DateFilter 
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            filterPayments={filterPayments}
                            processing={processing}
                        />
                    </div>

                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50'>
                                    <tr>
                                        <th scope="col" className='px-4 py-3 w-[20%]'>Transaction ID</th>
                                        <th scope="col" className='px-4 py-3 w-[40%]'>Payee</th>
                                        <th scope="col" className='px-4 py-3'>Amount (GHC)</th>
                                        <th scope="col" className='px-4 py-3'>Date Paid</th>
                                        {/* <th scope="col" className='px-4 py-3'>Status</th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                    {payments && payments.length > 0 ? (
                                        payments.map((payment, index) => (
                                            <tr key={index} className="px-4 py-6 border-b dark:border-gray-100 text-sm font-normal tracking-wide text-gray-500">
                                                <td className='px-4 py-3'>
                                                    {payment?.transactionId}
                                                </td>
                                                <td className='px-4 py-3 inline-flex flex-col'>
                                                    <span className='font-medium'>
                                                        {payment?.updatedBy === "staff" ? "Staff" : "Client"}
                                                    </span>
                                                    <span className='text-xs'>
                                                        {payment?.loanId}
                                                    </span>
                                                </td>
                                                <td className='px-4 py-3'>
                                                    {payment?.amount}
                                                </td>
                                                <td className='px-4 py-3'>
                                                    {dateToHumanFormatShort(payment?.createdAtms)}
                                                </td>
                                                {/* <td className='px-4 py-3'>
                                                    
                                                </td> */}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="px-4 py-6 border-b dark:border-gray-100 text-sm font-normal tracking-wide text-gray-500">
                                            <td className='px-4 py-3 text-center' colSpan="4">
                                                No payments found
                                            </td>
                                        </tr>   
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </section>
            </main>

        </div>
    )
}

export default Index