import { useState } from "react";
import { dateToHumanFormat } from "../../../utils/constants";

function RepayDates({ dates }) {

    const show = 24;
    const [itemsToShow, setItemsToShow] = useState(show);
    const [expanded, setExpanded] = useState(false);

    const showMore = () => {
        setItemsToShow(expanded ? show : dates.length);
        setExpanded(!expanded);
    }

    return (
        <>
        {dates.slice(0, itemsToShow).map((date, index) => (
            <div key={index} className="rounded-md p-4 bg-gray-100 flex items-center justify-center">
                <p className="text-xs font-semibold text-gray-600">{dateToHumanFormat(date)}</p>
            </div>
        ))}

        {dates.length > show && (
            <div className="col-span-full">
                <div className="relative w-[95%] mx-auto flex py-1.5 items-center">
                    <div className="flex-grow border-t border-slate-200"></div>
                    <button type="button" className="flex-shrink mx-4 text-sm text-gray-400" onClick={showMore}>
                        {expanded ? "Show less dates" : "Show more dates"}
                    </button>
                    <div className="flex-grow border-t border-slate-200"></div>
                </div>
            </div>
        )}
        </>
    )
}

export default RepayDates