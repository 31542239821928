import { Tab } from '@headlessui/react';
import { Topbar, Sidebar, AccountIndex, ApplicationIndex, CreditIndex, FilesIndex, Loader, BreadCrump, LoanSettings } from "../../components";
import { useLocation } from "react-router-dom";
import { loanStates } from "../../utils/constants";
import { useLoan } from '../../hooks';

function Loan() {

    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const loanState = path[1];
    const loanId = path[2];

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const { isLoading, loan } = useLoan(
        { loanId, loanType : null, state : loanState, approved : false, active : loanState === loanStates.active, completed : loanState === loanStates.completed, rejected : loanState === loanStates.rejected}
    )

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container space-y-6'>

                {loan ?
                    <>
                    <div className='flex items-center justify-between'>
                        <BreadCrump 
                            path={loanState === loanStates.active ? "/active-loans" : loanState === loanStates.completed ? "/completed-loans" : "/rejected-loans"}
                            main={loanState === loanStates.active ? "Active Loans" : loanState === loanStates.completed ? "Completed Loans" : "Rejected Loans"}
                            sub={isLoading ? <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} /> : `${loan?.type} - ${loan?._id}`}
                        />

                        {!isLoading ? <LoanSettings loan={loan} /> : null}
                    </div>

                    <div>
                        <Tab.Group>
                            <Tab.List className="flex space-x-1 rounded-xl p-1">
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 px-2 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Loan Account
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 px-2 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Application
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 px-2 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Credit Bureau
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 px-2 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Files
                                </Tab>
                            </Tab.List>

                            <Tab.Panels className="mt-2">
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <AccountIndex loan={loan} />
                                </Tab.Panel>
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <ApplicationIndex loan={loan} />
                                </Tab.Panel>
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <CreditIndex loan={loan} />
                                </Tab.Panel>
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <FilesIndex loan={loan} />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </>
                : 
                <div className='w-full py-2 inline-flex justify-center items-center'>
                    <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />
                </div>
                }
                </section> 
            </main>
        </div>
    )
}

export default Loan