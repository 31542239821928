import { useState } from 'react';
import { Button, FormInput } from "../../../components";
import { useSelector } from "react-redux";
import { selectIDToken, selectAccessToken } from "../../../slices/staffSlice";
import { fetchRequests } from '../../../Requests/getRequests';

function Payment({ loan }) {

    const idToken = useSelector(selectIDToken);
    const accessToken = useSelector(selectAccessToken);

    const [processing, setProcessing] = useState(false);
    const [status, setStatus] = useState(false);

    const handleDisbursement = async(e) => {
        e.preventDefault();
        setProcessing(true);

        let url = `/disburse/initiate`;
        let headers = {
            "Content-Type" : "application/json",
            "Authorization" : `Bearer ${accessToken}`,
            "client-token" : `${idToken}`
        }

        let data = {
            loanId : loan?._id,
            loanType : loan?.loanType
        }

        let res = await fetchRequests(url, "POST", headers, {}, data);
        if(res && res.status === true){
            setStatus(`${res?.message}, status: ${res?.data.status} ...`);

            setTimeout(() => {
                const getStatus = async() => {
                    let statusUrl = `/disburse/verify/webhook/${res?.data?.merchantXRef}`
                    let response = await fetchRequests(statusUrl, "GET", headers, {}, {});
                    console.log(response);
                }

                getStatus(res);
            }, 300000)
        }

        setProcessing(false);
    }

    return (
        <div className='rounded-md'>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table className='min-w-full'>
                        <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                            <tr>
                                <th scope="col" className='px-4 py-3'>
                                    Add Transaction
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                                <td className='px-4 py-3'>
                                    <form onSubmit={handleDisbursement}>
                                        <div className='grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-6'>
                                            <div>
                                                <label htmlFor="transactionType" className='form-label'>Transction Type</label>
                                                <select name="transactionType" id="transactionType" className="form-input">
                                                    <option value="Repayment">Repayment</option>
                                                    <option value="WriteOff">Write-Off</option>
                                                </select>
                                            </div>

                                            <div>
                                                <label htmlFor="transactionID" className='form-label'>Transaction ID</label>
                                                <FormInput 
                                                    type="number"
                                                    name="transactionID"
                                                    id="transactionID"
                                                    required
                                                    disabled={false}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="transactionDate" className='form-label'>Transaction Date</label>
                                                <FormInput 
                                                    type="date"
                                                    name="transactionDate"
                                                    id="transactionDate"
                                                    required
                                                    disabled={false}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="repaymentAmount" className='form-label'>Amount</label>
                                                <FormInput 
                                                    type="number"
                                                    name="repaymentAmount"
                                                    id="repaymentAmount"
                                                    required
                                                    disabled={false}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="paymentStartDate" className='form-label'>Payment Start Date (Optional)</label>
                                                <FormInput 
                                                    type="number"
                                                    name="paymentStartDate"
                                                    id="paymentStartDate"
                                                />
                                            </div>
                                        </div>

                                        <div className='inline-flex items-center space-x-4 mt-6'>
                                            <Button 
                                                btnText={"Submit Payment"}
                                                btnType="submit"
                                                btnClasses={"form-button w-auto"}
                                                processing={processing}
                                            />

                                            {status && 
                                            <div className='py-2 px-6 bg-emerald-400 rounded-md text-white'>
                                                <span>
                                                    {status}
                                                </span>
                                            </div>
                                            }
                                        </div>
                                    </form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Payment