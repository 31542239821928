import React from 'react'

function Heading({ size, color, text }) {
    return (
        <h1 className={`my-3 leading-6 text-display ${size} ${color}`}>
            {text}
        </h1>
    )
}

export default Heading