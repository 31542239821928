import React from 'react';
import { Heading } from "../../components";

function PortfolioSummary() {
    return (
        <section className='section-container space-y-6'>
            <Heading 
                size="text-3xl font-medium"
                color="text-gray-700"
                text="Portfolio Report Summary"
            />

            {/* table 1 */}
            <div className='rounded-md'>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className='min-w-full'>
                            <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                <tr>
                                    <th scope="col" colSpan={4} className='px-4 py-3 text-center'>CedisPay Report - Day</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Start Period
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Mon Dec 05 2022
                                    </td>
                                    <td className="px-4 py-3 border">
                                        End Period
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Mon Dec 05 2022
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* end of table 1 */}

            {/* table 2 */}
            <div className='rounded-md'>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className='min-w-full'>
                            <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                <tr>
                                    <th scope="col" colSpan={2} className='px-4 py-3 text-center'>Capital Me</th>
                                    <th scope="col" colSpan={1} className='px-4 py-3 text-center w-12'></th>
                                    <th scope="col" colSpan={2} className='px-4 py-3 text-center'>Loan Me</th>
                                    <th scope="col" colSpan={1} className='px-4 py-3 text-center w-12'></th>
                                    <th scope="col" colSpan={2} className='px-4 py-3 text-center'>General Portfolio</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className="px-4 py-6 border-b">
                                    <td colSpan={8} className="px-4 py-3">
                                    
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td colSpan={2} className="px-4 py-3 border font-bold">
                                        Principal:
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td colSpan={2} className="px-4 py-3 border font-bold">
                                        Principal:
                                    </td>
                                    <td className="px-4 py-3 border">
                                        
                                    </td>
                                    <td colSpan={2} className="px-4 py-3 border font-bold">
                                        Principal:
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Opening Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        1184472.19
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Opening Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        63516.3
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Opening Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        1247988.49
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Loans Disbursed
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Loans Disbursed
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Loans Disbursed
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0.00
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Repayments
                                    </td>
                                    <td className="px-4 py-3 border">
                                        77076.58
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Repayments
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Repayments
                                    </td>
                                    <td className="px-4 py-3 border">
                                        7706.58
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Write Offs
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Write Offs
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Write Offs
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0.00
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Closing Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        1176765.61
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Closing Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        63516.3
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Closing Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        1240281.91
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td colSpan={8} className="px-4 py-3">
                                    
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td colSpan={2} className="px-4 py-3 border font-bold">
                                        Interest:
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td colSpan={2} className="px-4 py-3 border font-bold">
                                        Interest:
                                    </td>
                                    <td className="px-4 py-3 border">
                                        
                                    </td>
                                    <td colSpan={2} className="px-4 py-3 border font-bold">
                                        Interest:
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Opening Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        230971.5
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Opening Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        8778.06
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Opening Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        239749.56
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Loans Disbursed
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Loans Disbursed
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Loans Disbursed
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0.00
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Repayments
                                    </td>
                                    <td className="px-4 py-3 border">
                                        1257.59
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Repayments
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Repayments
                                    </td>
                                    <td className="px-4 py-3 border">
                                        1257.59
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Write Offs
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Write Offs
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Write Offs
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0.00
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        Closing Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        229713.91
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Closing Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        8778.06
                                    </td>
                                    <td className="px-4 py-3 border">
                                    
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Closing Balance
                                    </td>
                                    <td className="px-4 py-3 border">
                                        238491.97
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* end of table 2 */}
        </section>
    )
}

export default PortfolioSummary