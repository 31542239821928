import { useState } from 'react';
import { authStaff } from '../../utils/constants';
import { useSelector } from "react-redux";
import { selectAccessToken, selectStaffId } from "../../slices/staffSlice";
import { adminPortalRequest } from '../../Requests/getRequests';
import { Loader } from "../../components";

function ValidateId({ status, type, idNumber, setClient, setAlert }) {

    const accessToken = useSelector(selectAccessToken);
    const clientId = useSelector(selectStaffId);
    const [verifyLoader, setVerifyLoader] = useState(false);

    const verifyAccount = async() => {
        setVerifyLoader(true);
        let verifyUrl = `/account/client/id/validate`;
        let headers = authStaff(accessToken);
        let params = {
            userId : clientId,
            idType : type,
            idNumber : idNumber
        }

        let res = await adminPortalRequest(verifyUrl, "GET", headers, params, {});
        if(res?.code === "AuthenticatiionFailedError"){
            setVerifyLoader(false);
            setAlert({
                type : "error",
                state : true,
                message : "Your session has expired"
            })
        } 
        
        if(res?.status !== true){
            setVerifyLoader(false);
            setAlert({
                type : "error",
                state : true,
                message : `${res?.message}`
            })
            setTimeout(() => {
                setAlert({state: null})
            }, 2500)
        } else {
            // update user
            let clientUrl = `/user/client/find`;
            let params = {
                param : clientId
            }
            let updatedUser = await adminPortalRequest(clientUrl, "GET", headers, params, {});
            if(updatedUser?.code){
                setVerifyLoader(false);
                setAlert({
                    type : "error",
                    state : true,
                    message : "Your session has expired"
                })
            } else {
                setClient(updatedUser)
                setVerifyLoader(false);
                setAlert({
                    type : "success",
                    state : true,
                    message : `${res?.message}`
                })
                setTimeout(() => {
                    setAlert({state: null})
                }, 2500)
            }
        }
    }

    return (
        (status ?
        <span className='action-buttons disabled-button text-emerald-500'>
            IDs Validated
        </span>
        :
        <span className='action-buttons' onClick={verifyAccount}>
            {verifyLoader ? <Loader color="text-gray-700" size="5" /> : "Validate ID"}
        </span>
        )
    )
}

export default ValidateId