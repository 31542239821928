import React from 'react';
import { LoanInfo, ApprovalHistory } from "../../../components";

function Index({ loan }) {
    
    return (
        <div className='application-content'>
            <div className='rounded-md py-4 space-y-8'>
                <div className='space-y-4'>
                    <h4 className='text-md text-display font-medium leading-6'>
                        History
                    </h4>

                    <div className='rounded-md'>
                        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                <table className='min-w-full'>
                                    <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                        <tr>
                                            <th scope="col" className='px-4 py-3 w-[20%]'>Admin</th>
                                            <th scope="col" className='px-4 py-3 w-[10%]'>Action</th>
                                            <th scope="col" className='px-4 py-3 w-[55%]'>Comments</th>
                                            <th scope="col" className='px-4 py-3 w-[15%]'>Date</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {loan?.history && loan?.history.length > 0 ? 
                                            loan?.history.map((item, index) => (
                                                <ApprovalHistory 
                                                    key={index}
                                                    item={item}
                                                />
                                            ))
                                        : 
                                        <tr className="px-4 py-6 border-b text-gray-500 text-center hover:text-gray-700 hover:bg-gray-50">
                                            <td className='px-4 py-3' colSpan={4}>No History</td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='space-y-4'>
                    <h4 className='text-md text-display font-medium leading-6'>
                        Application Details
                    </h4>

                    <div className='rounded-md'>
                        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                <table className='min-w-full'>
                                    <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                        <tr>
                                            <th scope="col" className='px-4 py-3 w-auto'>Information</th>
                                            <th scope="col" className='px-4 py-3 w-96'>Details</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <LoanInfo loan={loan} />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index