import axios from "axios";

// base url to gateway which will do all the proxies
const gatewayEndPoint = process.env.REACT_APP_API_STAGING;

class RequestError extends Error {
    constructor(message, status) {
        super(message);
        this.name = `RequestError`;
        this.status = status;
    }
}

const requestOptions = {
    method : "GET",
    withCredentials : false,
    headers : {
        "Content-Type" : "application/json"
    }
}

const getRequest = async(url, options = requestOptions) => {
    try {
        let res = await axios(process.env.REACT_APP_API_STAGING + url, options);
        return res.data;
    } catch (error) {
        throw new RequestError(error.response.data, error.response.status)
    }
}

// const getRequest = async(url) => {
//     let requestOptions = {
//         method : "GET",
//         withCredentials : false,
//         headers : {
//             "Content-Type" : "application/json"
//         }
//     }

//     try {
        
//         let res = await axios(process.env.REACT_APP_API_STAGING + url, requestOptions);

//         if(res.status === 400 || res.status === 500){
//             console.log(`Error is: ${res.data}`)
//         } else {
//             // console.log(res.data)
//             return res.data;
//         }

//     } catch (error) {
//         if(error){
//             return error.response.data
//         }
//     }
// }

const getRequestWithParams = async(url,data) => {
    let requestOptions = {
        method : "GET",
        withCredentials : false,
        headers : {
            "Content-Type" : "application/json"
        },
        params : data
    }

    try {
        
        let res = await axios(process.env.REACT_APP_API_STAGING + url, requestOptions);

        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            // console.log(res.data)
            return res.data;
        }

    } catch (error) {
        if(error){
            if(error.response.status === 500){
                return {
                    err : error.response.statusText
                }
            }
            
            return error.response.data
        }
    }
}

const accessFileRequest = async(url, headers, params) => {
    let requestOptions = {
        method : "GET",
        withCredentials : false,
        headers,
        params,
        responseType : "blob"
    }

    try {
        
        let res = await axios(gatewayEndPoint + url, requestOptions);

        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            // console.log(res.data)
            return res;
        }

    } catch (error) {
        if(error){
            if(error.response.status === 500){
                return {
                    err : error.response.statusText
                }
            }
            
            return error.response.data
        }
    }
}


const uploadFileRequest = async(url, headers, formData) => {
    let requestOptions = {
        method : "POST",
        withCredentials : false,
        headers,
        data : formData
    }

    try {
        let res = await axios(gatewayEndPoint + url, requestOptions);
        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            // console.log(res.data)
            return res?.data;
        }

    } catch (error) {
        if(error){
            if(error.response.status === 500){
                return {
                    err : error.response.statusText
                }
            }
            
            return error.response.data
        }
    }
}


const fetchRequests = async(url, method, headers, params, data) => {
    let options = {
        method : method,
        withCredentials : false,
        headers,
        params,
        data
    }

    try {

        let res = await axios(gatewayEndPoint + url, options);
        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            return res.data;
        }
        
    } catch (error) {
        if(error){
            if(error.response.status === 500){
                return {
                    err : error.response.statusText
                }
            }

            return error.response.data
        }
    }
}

const adminPortalRequest = async(url, method, headers, params, data) => {
    let options = {
        method : method,
        withCredentials : false,
        headers,
        params,
        data
    }

    try {

        let res = await axios(gatewayEndPoint + url, options);
        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            return res.data;
        }
        
    } catch (error) {
        if(error){
            if(error.response.status === 500){
                return {
                    err : error.response.statusText
                }
            }
            
            return error.response.data
        }
    }
}

const reportsRequest = async(url, method, headers, params, data) => {
    let options = {
        method : method,
        withCredentials : false,
        headers,
        params,
        data,
        responseType : "blob"
    }

    try {

        let res = await axios(process.env.REACT_APP_STAGING_REPORT + url, options);
        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            return res;
        }
        
    } catch (error) {
        if(error){
            if(error.response.status === 500){
                return {
                    err : error.response.statusText
                }
            }
            
            return error.response.data
        }
    }
}

export {
    getRequest,
    getRequestWithParams,
    accessFileRequest,
    uploadFileRequest,
    fetchRequests,
    adminPortalRequest,
    reportsRequest
}