import { useState } from 'react';
import { useSelector } from "react-redux";
import { selectIDToken, selectAccessToken } from "../../../slices/staffSlice";
import { Button } from "../../../components";
import { reportsRequest } from '../../../Requests/getRequests';
import { saveAs } from 'file-saver';
import { hyphenDate } from '../../../utils/constants';

function PaymentHistory({ loan }) {

    // const loan = useSelector(selectLoan);
    const idToken = useSelector(selectIDToken);
    const accessToken = useSelector(selectAccessToken);

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);

    const exportPayments = async(e) => {
        e.preventDefault();
        setProcessing(true);

        if(error){
            setError(false)
        }

        let params = {
            loanId : loan?.loanId,
            loanType : loan?.loanType,
            reportType : "client_payments"
        }

        let url = `/report`;
        let headers = {
            "Content-Type" : "application/json",
            "Authorization" : `Bearer ${accessToken}`,
            "client-token" : `${idToken}`
        }

        let res = await reportsRequest(url, "GET", headers, params, {});
        if(res && res.status === 200){
            setProcessing(false);
            let excelData = res?.data;
            saveAs(excelData, `payment-${hyphenDate()}.xlsx`)
        } else {
            setProcessing(false);
            setError(`${res.message}`)
        }

        setProcessing(false);
    }

    return (
        <div className='inline-flex items-center space-x-4 mt-6'>
            <Button 
                btnText={"Export Payments"}
                btnClasses={"form-button w-auto"}
                processing={processing}
                btnFunction={exportPayments}
            />
        </div>
    )
}

export default PaymentHistory