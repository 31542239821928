import { Button } from '../../../components';

function DateFilter({ startDate, setStartDate, endDate, setEndDate, filterPayments, processing }) {

    return (
        <div>
            <form className='grid grid-cols-3 gap-4'>
                <input 
                    type="date"
                    name={"startDate"}
                    id={"startDate"}
                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    // min={new Date().toISOString().split('T')[0]}
                    max={new Date().toISOString().split('T')[0]}
                />

                <input 
                    type="date"
                    name={"endDate"}
                    id={"endDate"}
                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                    // min={new Date().toISOString().split('T')[0]}
                    max={new Date().toISOString().split('T')[0]}
                />

                <Button 
                    btnText={"Filter"}
                    btnType={"submit"}
                    btnFunction={filterPayments}
                    btnClasses={"w-full bg-light-blue hover:bg-mild-blue text-white font-bold px-4 mt-2 rounded-md"}
                    processing={processing}
                />
            </form>
        </div>
    )
}

export default DateFilter