import { useState } from 'react';
import { AccountTabs, Disburse, Payment, Transactions, LoanBalance, PaymentHistory, Button } from "../../../components";
import { authStaff, loanStatesOld } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { fetchRequests } from '../../../Requests/getRequests';
import { show } from '../../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Index({ loan }) {

    const facilityLetter = "Proceed to Files tab to upload signed facility letter";
    const facilityLetterUploaded = "Confirm signed facility letter";
    const disburseLoan = "Confirm loan amount and other details to disburse";
    const activeLoan = "Add a loan payment transaction";

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [approveProcess, setApproveProcess] = useState(false);
    const [disapproveProcess, setDisapproveProcess] = useState(false);

    const approveLoanRequest = async(e) => {
        e.preventDefault();
        setApproveProcess(true);

        let url = `/loans/staff/confirm-facility-letter`;
        let headers = authStaff(accessToken);
        let data = {
            _id : loan._id,
            type : loan.type.toLowerCase(),
        }

        let res = await fetchRequests(url, "put", headers, {}, data);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }
        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        } else {
            dispatch(show({
                type : "success",
                state : true,
                message : `${res?.message}`
            }))
            setTimeout(() => {
                window.location.reload();
            },1500)
        }

        setApproveProcess(false);
    }

    const rejectLoanRequest = async(e) => {
        e.preventDefault();
        setDisapproveProcess(true);

        setTimeout(() => {
            setDisapproveProcess(false);
        })
    }

    return (
        <div className='application-content'>
            <div className='flex flex-col space-y-10'>

                {/* status of the loan */}
                <div className='rounded-md'>
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                    <tr>
                                        <th scope="col" className='px-4 py-3'>Status - Loan application approved</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className="px-4 py-6 border-b text-gray-500 text-lg">
                                        <td className='px-4 py-3'>
                                            <span className="text-emerald-600 font-medium">Next Action</span>: {loan?.state === loanStatesOld.approved && loan?.facilityLetterUploaded && !loan?.facilityLetterApproved ? facilityLetterUploaded : loan?.state === loanStatesOld.approved && !loan?.facilityLetterUploaded ? facilityLetter : loan?.state === loanStatesOld.approved && loan?.facilityLetterUploaded && loan?.facilityLetterApproved ? disburseLoan : activeLoan}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {loan?.state === loanStatesOld.approved && loan?.facilityLetterUploaded && !loan?.facilityLetterApproved && (
                    <form>
                        <div>
                            <label htmlFor="comment" className='form-label'>
                                Comments
                            </label>
                            <textarea name="comment" id="comment" cols="30" rows={4} className="form-textarea"></textarea>
                        </div>

                        <div className='inline-flex items-center justify-between mt-5 space-x-4'>
                            <Button 
                                btnText="Approve"
                                btnType="button"
                                btnClasses="form-button"
                                processing={approveProcess}
                                btnFunction={approveLoanRequest}
                            />

                            <Button 
                                btnText="Rejected"
                                btnType="button"
                                btnClasses="form-button-default"
                                processing={disapproveProcess}
                                btnFunction={rejectLoanRequest}
                            />
                        </div>
                    </form>
                )}
                {/* end status of the loan */}

                {/* disbursement of the loan */}
                {loan?.state === loanStatesOld.approved && loan?.facilityLetterApproved && 
                    <Disburse 
                        loan={loan}
                    />
                }
                {/* end disbursement of the loan */}

                {/* payment of loan */}
                {loan?.state === loanStatesOld.active && 
                <>
                    <Payment 
                        loan={loan}
                    />

                    <AccountTabs 
                        loan={loan}
                    />

                    <LoanBalance loan={loan} />

                    <Transactions loan={loan} />

                    <PaymentHistory loan={loan} />
                </>
                }
                {/* end payment of loan */}

            </div>
        </div>
    )
}

export default Index