import CountUp from "react-countup";

function NumCount({ symbol, number, deci }) {
    return (
        <CountUp
            className="account-balance"
            start={0}
            end={number}
            duration={2.75}
            separator=""
            decimals={deci ? 2 : 0}
            decimal={deci}
            prefix={symbol ? `${symbol} ` : ""}
            suffix=""
        />
    )
}

export default NumCount