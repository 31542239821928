import { SysFile } from "../../../components";

function SysFiles({ loan }) {

    const files = loan?.sysFiles;

    return (
        <div className='space-y-4'>
            <h4 className='text-md text-display font-medium leading-6'>
                System files
            </h4>

            <div className='rounded-md'>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className='min-w-full'>
                            <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                <tr>
                                    <th scope="col" className='px-4 py-3'>Name</th>
                                    <th scope="col" className='px-4 py-3 text-right'>View</th>
                                </tr>
                            </thead>

                            <tbody>
                                {files && files.length > 0 ? 
                                    files?.map((file, index) => (
                                        <SysFile 
                                            key={index}
                                            file={file}
                                            loanId={loan?.loanId}
                                            phone={loan?.phone}
                                        />
                                    ))
                                :
                                    <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700">
                                        <td className='px-4 py-3 text-center' colSpan={2}>
                                            <span className="font-medium">No files uploaded</span>
                                        </td>
                                    </tr>
                                } 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SysFiles