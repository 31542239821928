import { NumCount } from "../../components";

function Card({ title, count }) {
    return (
        <div className='side-by-side-card'>
            <span className="text-gray-600 text-xs px-2 py-1 bg-slate-100 w-max rounded-md">
                {title}
            </span>

            <span className="text-4xl font-semibold">
                {/* {count === 0 ? count : count ? count : <Loader size="w-5 h-5" />} */}
                <NumCount symbol={""} number={count} deci={""} />
            </span>
        </div>
    )
}

export default Card