import { useState, useEffect } from 'react'
import { loanFilters } from '../../utils/constants';
import { useLoans } from '../../hooks';

function Filter({ endpoint, state, setLoading, setLoans }) {

    const [loanType, setLoanType] = useState(loanFilters[0].value);

    const { isLoading, loans } = useLoans(
        { loanType : loanType, state : state, active : false, completed : false, rejected : false, endpoint : endpoint }
    );

    useEffect(() => {
        setLoading(isLoading);
        setLoans(loans);
    }, [isLoading, loans, setLoading, setLoans])

    return (
        <form className='w-44 lg:w-[400px] flex items-center'>
            <label htmlFor="loanType" className='w-16 lg:w-36 hidden lg:block'>Filter By:</label>
            <select id="loanType" name="loanType" className="block w-full rounded-md border py-2 px-1.5 text-gray-900 shadow-sm focus:outline-none sm:max-w-xs sm:text-sm sm:leading-6" onChange={(e) => setLoanType(e.target.value)}>
                {loanFilters.map((loan) => (
                    <option key={loan.id} value={loan.value}>
                        {loan.name}
                    </option>
                ))}
            </select>
        </form>
    )
}

export default Filter