import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/tailwind.css";
import App from './App';
import { BrowserRouter } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/es/integration/react";
import { Toast } from './components';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HttpsRedirect>
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <Toast />
                        <App />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    </HttpsRedirect>
);