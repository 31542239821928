import { useState } from 'react'

function Tooltip({ children, text, position }) {

    const [show, setShow] = useState(false);

    const positions = {
        top: 'bottom-full mb-2',
        bottom: 'top-full mt-2',
        left: 'right-full mr-2',
        right: 'left-full ml-2',
    };

    return (
        <div className='relative flex items-center isolate'>
            <div
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                className='cursor-pointer relative'
            >
                {children}
            </div>
            {show && (
                <div className={`absolute ${positions[position]} w-max bg-black text-white text-xs px-2 py-1 rounded-md z-50 shadow-md`}>
                    {text}
                </div>
            )}
        </div>
    )
}

export default Tooltip