import { useEffect, useState } from 'react'
import { Topbar, Sidebar, ReversalItem } from "../../components";
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { authStaff } from '../../utils/constants';
import { fetchRequests } from '../../Requests/getRequests';
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);

    useEffect(() => {
        let unsubscribed = false;
        const getReversals = async() => {
            let url = `/loans/staff/reversals`;
            let headers = authStaff(accessToken);

            let res = await fetchRequests(url, "GET", headers, {}, {});

            if(res.code === 401){
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }
            
            if(!unsubscribed){
                if(res?.err === true){
                    dispatch(show({
                        type : "error",
                        state : true,
                        message : res?.err
                    }))
                    return;
                } else {
                    setItems(res);
                }
            }
        }

        getReversals();

        return () => {
            unsubscribed = true
        }

    }, [accessToken, dispatch, navigate])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container space-y-10'>

                    <h3 className='text-3xl font-medium leading-6 text-display'>
                        Transaction Reversal Requests ({items && items.length})
                    </h3>

                    <div className='grid grid-cols-1 border rounded-md divide-y'>
                        {items && items.length > 0 ? (
                            items.map((rev) => (
                                <ReversalItem 
                                    key={rev?._id}
                                    item={rev}
                                    setItems={setItems}
                                />
                            ))
                        ) : (
                            <p className='p-4 text-center text-slate-500'>
                                No requests available!
                            </p>
                        )}
                    </div>

                </section>
            </main>
        </div>
    )
}

export default Index