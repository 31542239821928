import React from 'react'

function FormRadio({ label, type, content, value, setValue }) {

    const handleCheck = (e) => {
        let checked = e.target.name
        
        if(value.find(item => item === checked)) {
            setValue(value.filter(item => item !== checked))
        } else {
            setValue([...value, checked])
        }
    }

    return (
        <fieldset>
            <legend className='form-label'>
                {label}
            </legend>

            <div className="relative flex flex-col gap-y-3 mt-2 ml-2">
            {content?.map((item, index) => (
                <label key={index} htmlFor={item.value} className="inline-flex gap-2 items-center text-sm text-gray-700 hover:cursor-pointer">
                    <input 
                        type={type}
                        id={item.value}
                        name={item.value}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        defaultChecked={value?.find(val => val === item.value)}
                        value={value}
                        onChange={handleCheck}
                    />

                    {item.text}
                </label>
            ))}
            </div>
        </fieldset>
    )
}

export default FormRadio