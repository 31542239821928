import { useState } from 'react';
import { Topbar, Sidebar, Button, AccountIndex, ApplicationIndex, CreditIndex, FilesIndex, Loader, FormTextArea, BreadCrump } from "../../components";
import { Tab } from '@headlessui/react';
import { fetchRequests } from "../../Requests/getRequests";
import { useSelector, useDispatch } from "react-redux";
import { selectAccessToken, selectAdminRole } from "../../slices/staffSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRoles, authStaff } from "../../utils/constants";
import { show } from '../../slices/toastSlice';
import { useLoan } from '../../hooks';

function Index() {

    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const adminRole = useSelector(selectAdminRole);
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const loanType = path[1];
    const loanId = path[2];
    const [approveProcess, setApproveProcess] = useState(false);
    const [disapproveProcess, setDisapproveProcess] = useState(false);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const { isLoading, loan } = useLoan(
        { loanId, loanType, state : null, approved : false, active : false, completed : false }
    );

    const approveLoanRequest = async(e) => {
        e.preventDefault();
        setApproveProcess(true);

        const form = document.getElementById("app-form");
        const data = new FormData(form);
        let newObj = {};
        for (let [key, value] of data) {
            newObj[key] = value;
        }

        let url = `/loans/staff/approveRejectLoan`;
        let headers = authStaff(accessToken);
        let body = {
            _id : loanId,
            type : loanType,
            approved : true,
            ...newObj,
            adminRole : adminRole
        }
        let res = await fetchRequests(url, "POST", headers, {}, body);
        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }
        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        } else {
            dispatch(show({
                type : "success",
                state : true,
                message : `${res?.message}`
            }))
            setTimeout(() => {
                navigate("/loans");
            },1500)
        }

        setApproveProcess(false);
    }

    const rejectLoanRequest = async(e) => {
        e.preventDefault();
        setDisapproveProcess(true);

        const form = document.getElementById("app-form");
        const data = new FormData(form);
        let newObj = {};
        for (let [key, value] of data) {
            newObj[key] = value;
        }

        let url = `/loans/staff/rejectRejectLoan`;
        let headers = authStaff(accessToken);
        let body = {
            _id : loanId,
            type : loanType,
            approved : false,
            ...newObj,
            adminRole : adminRole
        }
        let res = await fetchRequests(url, "POST", headers, {}, body);
        setDisapproveProcess(false);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        dispatch(show({
            type : "error",
            state : true,
            message : res?.message
        }))
        setTimeout(() => {
            navigate("/loans");
        },1200)
    
    }

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container space-y-6'>

                    {loan ? 
                        <>
                        <BreadCrump 
                            path='/loans'
                            main="Loans"
                            sub={isLoading ? <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} /> : `${loan?.type} - ${loan?._id}`}
                        />

                        <Tab.Group>
                            <Tab.List className="flex space-x-1 rounded-xl p-1">
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Application
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Credit Bureau
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Files
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Loan Account
                                </Tab>
                            </Tab.List>

                            <form id='app-form'>
                                <Tab.Panels className="mt-2">
                                    <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                        <ApplicationIndex loan={loan} />
                                    </Tab.Panel>
                                    <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                        <CreditIndex loan={loan} />
                                    </Tab.Panel>
                                    <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                        <FilesIndex loan={loan} />
                                    </Tab.Panel>
                                    <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                        <AccountIndex loan={loan} />
                                    </Tab.Panel>
                                </Tab.Panels>

                                
                                <div className='mt-6 py-4 px-2 space-y-6'>
                                    {loan?.adminStage === adminRoles.pm && (
                                        <div>
                                            <label htmlFor="overallAssessment" className='form-label'>
                                                Overall Assessment
                                            </label>
                                            <FormTextArea
                                                name="overallAssessment"
                                                id="overallAssessment"
                                                placeholder="Indicate why the loan should be approved"
                                                value={loan && loan?.overallAssessment}
                                                disabled={loan?.overallAssessment ? true : false}
                                            />
                                        </div>   
                                    )}
                                    <div>
                                        <label htmlFor="comment" className='form-label'>
                                            Comments
                                        </label>
                                        <textarea name="comment" id="comment" cols="30" rows={4} className="form-textarea"></textarea>
                                    </div>

                                    <div className='inline-flex items-center justify-between mt-5 space-x-4'>
                                        <Button 
                                            btnText="Approve"
                                            btnType="button"
                                            btnClasses="form-button"
                                            processing={approveProcess}
                                            btnFunction={approveLoanRequest}
                                        />

                                        <Button 
                                            btnText="Reject"
                                            btnType="button"
                                            btnClasses="form-button-default"
                                            processing={disapproveProcess}
                                            btnFunction={rejectLoanRequest}
                                        />
                                    </div>
                                </div>
                            </form>

                        </Tab.Group>

                        </>
                    : 
                    <div className='w-full py-2 inline-flex justify-center items-center'>
                        <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />
                    </div>
                    }

                </section>
            </main>
        </div>
    )

}

export default Index;