import { useState } from 'react';
import { Button } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { postRequest, putRequest } from "../../Requests/postRequests";
import { useDispatch } from "react-redux";
import { show } from '../../slices/toastSlice';
import Logo from "../../assets/images/logo.png";

function ForgotPassword() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_pass, setConfirm_pass] = useState("");
    const [stepOne, setStepOne] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);
    const [success, setSuccess] = useState();
    const [process, setProcess] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let data = {
            email
        }

        let url = `/auth/staff/forgot_password`;
        let res = await postRequest(url, data);

        if(res?.err){
            setProcess(false);
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        if(res?.message){
            setStepOne(false);
            setStepTwo(true);
        }

        setProcess(false);
    }

    const handleNewPassRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        if(confirm_pass !== password || confirm_pass === "" || password === ""){
            setProcess(false);
            dispatch(show({
                type : "error",
                state : true,
                message : (confirm_pass !== password) ? `Passwords do not match` : `Password fields can not be empty`,
            }))
            return;
        }

        let data = {
            email,
            code,
            password
        }

        let url = `/auth/staff/reset_password`;
        let res = await putRequest(url, data);
        
        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`,
            }))
            return;
        } else {
            setSuccess(true);
            dispatch(show({
                type : "success",
                state : true,
                message : `${res?.message}`,
            }))
            navigate("/login");
        }

        setProcess(false);
    }

    // useEffect(() => {
    //     document.title = `Home - ${process.env.REACT_APP_SITE_NAME}`;
    // })

    return (
        <>
        <div className='min-h-[100vh] min-w-[100vw] bg-gray-50 flex flex-col items-center justify-center relative'>
            <img src={Logo} alt='Company Logo' className='w-auto h-12 object-cover mx-auto mb-6' />

            <div className='w-[90%] md:w-[60%] lg:w-[30%] shadow-sm rounded-lg h-auto p-6 space-y-6 bg-white'>
                <h4 className='text-display font-medium text-3xl text-center'>
                    Forgot Password
                </h4>

                {stepOne && (
                    <form id="admin-login" onSubmit={handleRequest} className='grid grid-cols-1 gap-6 px-2'>
                        <div>
                            <label htmlFor="email" className='form-label'>Email Address</label>
                            <input type="email" name="email" id="email" className='form-input' autoComplete="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div>
                            <Button 
                                btnText={success ? <CheckCircleIcon className="w-5 h-5" /> : "Verify"}
                                btnClasses={"form-button"}
                                btnType="submit"
                                processing={process}
                            />
                        </div>
                    </form>
                )}

                {stepTwo && (
                    <form id="admin-login" onSubmit={handleNewPassRequest} className='grid grid-cols-1 gap-6 px-2'>
                        <div>
                            <label htmlFor="code" className='form-label'>Code</label>
                            <input type="number" name="code" id="code" className='form-input' required value={code} onChange={(e) => setCode(e.target.value)} maxLength={4} />
                        </div>
                        
                        <div>
                            <label htmlFor="password" className='form-label'>Password</label>
                            <input type="password" name="password" id="password" className='form-input' required value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        
                        <div>
                            <label htmlFor="confirm_pass" className='form-label'>Confirm Password</label>
                            <input type="password" name="confirm_pass" id="confirm_pass" className='form-input' required value={confirm_pass} onChange={(e) => setConfirm_pass(e.target.value)} />
                        </div>

                        <div>
                            <Button 
                                btnText={success ? <CheckCircleIcon className="w-5 h-5" /> : "Confirm"}
                                btnClasses={"form-button"}
                                btnType="submit"
                                processing={process}
                            />
                        </div>
                    </form>
                )}

                <p className='text-center'>
                    <Link to="/login" className='hover:underline'>Back to Login</Link>
                </p>

            </div>

        </div>
        </>
    )
}

export default ForgotPassword