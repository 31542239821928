import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

function BreadCrump({ path, main, sub }) {
    return (
        <div className='inline-flex min-h-[42px] w-max items-center gap-0.5 overflow-hidden border border-gray-200 rounded-lg px-1.5 mb-5 text-sm'>
            {main && 
                <Link to={path} className='text-gray-500 hover:bg-slate-100 px-2 py-1 rounded-md'>
                    {main}
                </Link>
            }
            {main && 
                <ChevronRightIcon className='h-3 w-3 text-gray-500' />
            }
            <span className="font-medium px-2 py-1 hidden sm:block">
                {sub ? sub : ""}
            </span>
        </div>
    )
}

export default BreadCrump