import { useEffect, useState } from "react";
import { ClientFile } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../../slices/staffSlice";
import { show } from "../../../slices/toastSlice";
import { fetchRequests } from "../../../Requests/getRequests";
import { authStaff } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";

function ClientFiles({ name, loan }) {

    const files = loan?.files;
    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        let unsubscribe = false;

        const getPhotos = async() => {
            let url = `/loans/staff/getClientPhotos`;
            let headers = authStaff(accessToken);

            let params = {
                phone : loan?.phone
            }

            let res = await fetchRequests(url, "GET", headers, params, {});
            
            if(res?.code === 401){
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }

            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : res?.err
                }))
                return;
            }

            if(!unsubscribe){
                setPhotos(res);
            }
        }

        if(name === "Client"){
            getPhotos();
        }

        return () => {
            unsubscribe = true;
        }
    }, [name, accessToken, dispatch, navigate, loan])

    return (
        <div className='space-y-4'>
            <h4 className='text-md text-display font-medium leading-6'>
                {name} files
            </h4>

            <div className='rounded-md'>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className='min-w-full'>
                            <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                <tr>
                                    <th scope="col" className='px-4 py-3'>Type of File</th>
                                    <th scope="col" className='px-4 py-3 w-auto text-right'>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {name === "Client" ? (
                                    photos?.map((photo, index) => (
                                        <ClientFile 
                                            key={index}
                                            loanId={loan?.loanId}
                                            file={photo}
                                            phone={loan?.phone}
                                            name={name}
                                        />
                                    ))
                                ) : (
                                files?.map((file, index) => (
                                    <ClientFile 
                                        key={index}
                                        loanId={loan?.loanId}
                                        file={file}
                                        phone={loan?.phone}
                                        name={name}
                                    />
                                )))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientFiles