import { useState } from 'react'
import { FormInput, Button } from '../../components';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { show } from '../../slices/toastSlice';
import { fetchRequests } from '../../Requests/getRequests';
import { authStaff } from '../../utils/constants';
import { selectAccessToken } from '../../slices/staffSlice';

function SearchClient() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);

    const [phone, setPhone] = useState("");
    const [process, setProcess] = useState(false);
    const [searchedClient, setSearchedClient] = useState(null);

    const handleSearch = async(e) => {
        e.preventDefault();
        setProcess(true);

        if(phone === ""){
            dispatch(show({
                type : "error",
                state : true,
                message : "Please enter a phone number"
            }))
            return;
        }

        let headers = authStaff(accessToken);

        let params = {
            phone
        }

        let url = `/auth/staff/client/search`;

        let res = await fetchRequests(url, "GET", headers, params, {});
        setProcess(false);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        setSearchedClient(res);

    }

    return (
        <section className='section-container'>
            <h3 className='text-3xl font-medium leading-6 text-display mb-3'>Search Clients</h3>

            <form className='flex space-x-4 mt-6' onSubmit={handleSearch}>
                <div className='w-96'>
                    <FormInput 
                        label="Phone Number"
                        type="text"
                        name="Phone Number"
                        id="Phone Number"
                        placeholder="Enter phone number"
                        required={true}
                        value={phone}
                        setValue={setPhone}
                    />
                </div>

                <div className='mt-6'>
                    <Button 
                        btnText={"Search"}
                        btntype={"button"}
                        btnClasses={"form-button mt-2"}
                        processing={process}
                    />
                </div>
            </form>

            {searchedClient && (
                (searchedClient !== null && searchedClient?._id) && (
                    <div className="block mt-6">
                        <h6 className='font-bold leading-6 text-display mb-3'>Search Results</h6>

                        <div className='w-100 flex flex-col space-y-1'>
                            <Link to={`/client/${searchedClient?._id}`} className='px-4 py-3.5 border border-gray-200 rounded-md hover:bg-gray-50 flex items-center divide-x divide-slate-200'>
                                <div className='pl-0 px-6'>
                                    {`${searchedClient?.otherNames} ${searchedClient?.surname}`}
                                </div>
                            </Link>
                        </div>
                    </div>
                )
            )}

        </section>
    )
}

export default SearchClient