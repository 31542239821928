import { Topbar, Sidebar, LoansTable, Loader } from "../../components";
import { useLoans } from '../../hooks';

function Index() {

    const { isLoading, loans } = useLoans(
        { loanType : null, state : null, active : false, completed : true, rejected : false, endpoint : null }
    );
    
    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>

                <section className='section-container'>
                    <div className='flex items-center justify-between mb-4 md:mb-10'>
                        <h3 className='text-3xl font-medium leading-6 text-display mb-6 inline-flex items-center'>
                            Completed Applications {isLoading && <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />}
                        </h3>
                    </div>
                    
                    <LoansTable loans={loans} />

                </section>

            </main>

        </div>
    )
}

export default Index