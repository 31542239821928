import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';

function RadioBtns({ options, setLoanType }) {

    return (
		<RadioGroup onChange={(e) => setLoanType(e)}>
			<RadioGroup.Label className="sr-only">Select loan type</RadioGroup.Label>
			<div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-6">
				{options.map((plan, index) => (
					<RadioGroup.Option
						key={index}
						value={plan.name.toLowerCase().replace(/\s/g, "")}
						className={({ active, checked }) =>
							`${active ? "ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300" : ""}
							${checked ? "bg-light-blue text-white" : "bg-white"} relative flex cursor-pointer rounded-lg px-5 py-4 shadow-sm border border-slate-200 hover:border-light-blue transition duration-100 delay-75`}>
						{({ active, checked }) => (
							<>
								<div className="flex w-full items-center justify-between">
									<div className="flex items-center">
										<div className="text-sm">
											<RadioGroup.Label as="p" className={`font-medium mb-1 ${checked ? "text-white" : "text-gray-900"}`}>
												{plan.name}
											</RadioGroup.Label>
											<RadioGroup.Description as="span" className={`inline text-gold-500 ${checked ? "text-sky-100" : "text-gray-500"}`}>
												<span>
													{plan.limit} {" | "} {plan.rate}% p.m.
												</span>
											</RadioGroup.Description>
										</div>
									</div>
									{checked && (
										<div className="shrink-0 text-light-blue bg-white rounded-full p-1">
											<CheckIcon className="h-4 w-4" />
										</div>
									)}
								</div>
							</>
						)}
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
	);
}

export default RadioBtns