import { useState } from 'react';
import { FormSelect, FormInput, FormUpload, Button, Modal, OtherDocsUpload } from "../../components";
import { optionsYN, missedPaymentOptions, billsHistoryOpts, insuranceOpts, purposeOfLoanOpts, paymentPlanOpts, paymentDurationOpts, incomeFrequencyOpts, automaticPaymentOpts, relationshipManagers, getDateAfterTimeframe, momoTransactionOpts, yearsInBusinessOpts, transFrequencyOpts, consistentOpts, nextMonthStartDate } from '../../utils/constants';
import LoanAuth from "./LoanAuth";
import { preCheckLoanEligibility } from '../../utils/loanProcessing';
import { Dialog } from '@headlessui/react';
import EligCheck from './EligCheck';
import BusInfo from './BusInfo';
import { useDispatch } from 'react-redux';
import { show } from '../../slices/toastSlice';
import NextOfKin from './NextOfKin';

function Capitalme({ client }) {

    let [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        setIsOpen(false)
    }
    
    const openModal = () => {
        setIsOpen(true)
    }

    const [businessSelect, setBusinessSelect] = useState();
    const [employedCurrently, setEmployedCurrently] = useState('');
    const [salaryStatus, setSalaryStatus] = useState('');
    const [incomeStatus, setIncomeStatus] = useState('');
    const [incomeAmt, setIncomeAmt] = useState('');
    const [debtAmt, setDebtAmt] = useState('');
    const [momo_transactions, setMomo_transactions] = useState('');
    const [numOfTransactions, setNumOfTransactions] = useState('');
    const [automatedPaymentsStatus, setAutomatedPaymentsStatus] = useState('');
    const [yearsInOperation, setYearsInOperation] = useState('');
    const [typeOfInsurance, setTypeOfInsurance] = useState('');
    const [transFreq, setTransFreq] = useState('');
    const [consistTrans, setConsistTrans] = useState('');
    const [outstandingLoans, setOutstandingLoans] = useState('');
    const [loanDefaultStatus, setLoanDefaultStatus] = useState('');
    const [defaultedLoanStatus, setDefaultedLoanStatus] = useState('');
    const [billsHistory, setBillsHistory] = useState('');
    const [billDefaultStatus, setBillDefaultStatus] = useState('');
    const [billsHistoryStatus, setBillsHistoryStatus] = useState('');
    const [loanPaymentHistory, setLoanPaymentHistory] = useState('');
    const [billPaymentHistory, setBillPaymentHistory] = useState('');
    const [savingHabits, setSavingHabits] = useState('');
    const [savingsFreq1, setSavingsFreq1] = useState('');
    const [savingsFreq2, setSavingsFreq2] = useState('');
    const [savingsFreq3, setSavingsFreq3] = useState('');
    const [eligibleSavingsHabit, setEligibleSavingsHabit] = useState('');
    const [amountReq, setAmountReq] = useState('');
    const [purpose, setPurpose] = useState('');
    const [paymentPlan, setPaymentPlan] = useState('');
    const [paymentDuration, setPaymentDuration] = useState('');
    const [repaySource, setRepaySource] = useState('');
    const [freqIncome, setFreqIncome] = useState('');
    const [autoDeduction, setAutoDeduction] = useState('');
    const [incomeProof, setIncomeProof] = useState('');
    const [guarAgreeSigned, setGuarAgreeSigned] = useState('');
    const [bankOrMomoStatement, setBankOrMomoStatement] = useState('');
    const [otherDocs, setOtherDocs] = useState([]);
    const [startDate, setStartDate] = useState(getDateAfterTimeframe('daily'));
    const [relManager, setRelManager] = useState('');
    const [guarantorFirstName, setGuarantorFirstName] = useState('');
    const [guarantorLastName, setGuarantorLastName] = useState('');
    const [guarantorPhone, setGuarantorPhone] = useState('');
    const [guarantorAddress, setGuarantorAddress] = useState('');
    const [guarantorRelationship, setGuarantorRelationship] = useState('');
    const guarantorIdType = 'GhanaCard';
    const [guarantorIdNumber, setGuarantorIdNumber] = useState('');
    const [guarantorDigitalAddress, setGuarantorDigitalAddress] = useState('');
    const [guarantorDob, setGuarantorDob] = useState('');
    const [nextOfKin, setNextOfKin] = useState('');
    const [nextOfKinPhone, setNextOfKinPhone] = useState('');
    const [nextOfKinAddress, setNextOfKinAddress] = useState('');
    const [nextOfKinRelationship, setNextOfKinRelationship] = useState('');
    const signedName = client?.name;
    const [preCheckData, setPreCheckData] = useState({});
    const [processing, setProcessing] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault()
        setProcessing(true);

        let data = { businessId : businessSelect._id, phone : client?.phone, employedCurrently, salaryStatus, incomeStatus, incomeAmt, debtAmt, momo_transactions, numOfTransactions, automatedPaymentsStatus, yearsInOperation, typeOfInsurance, transFreq, consistTrans, outstandingLoans, loanDefaultStatus, defaultedLoanStatus, billsHistory, billDefaultStatus, billsHistoryStatus, loanPaymentHistory, billPaymentHistory, savingHabits, savingsFreq1, savingsFreq2, savingsFreq3, eligibleSavingsHabit, amountReq, purpose, paymentPlan, paymentDuration, repaySource, freqIncome, autoDeduction, incomeProof, guarAgreeSigned, bankOrMomoStatement, otherDocs, startDate, relManager, guarantorFirstName, guarantorLastName, guarantorPhone, guarantorAddress, guarantorRelationship, guarantorIdType, guarantorIdNumber, guarantorDigitalAddress, guarantorDob, nextOfKin, nextOfKinPhone, nextOfKinAddress, nextOfKinRelationship, signedName }

        // check to see if files have been uploaded
        let incomeFile = document.getElementById("incomeProof");
        let guarantorFile = document.getElementById("guarAgreeSigned");
        let bankFile = document.getElementById("bankOrMomoStatement");
        
        if(!incomeFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach proof of income"
            }))
            return;
        }

        if(!guarantorFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach proof of guarantor signed agreement"
            }))
            return;
        }

        if(!bankFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach bank or momo statement"
            }))
            return;
        }

        // precheck loan application eligibility
        let response = await preCheckLoanEligibility("capitalme", data);
        setPreCheckData({ ...response, data });

        setTimeout(() => {
            setProcessing(false);
            openModal();
        }, 500)
    }

    return (
        <>
            {!businessSelect ? (
                <BusInfo 
                    setBusinessSelect={setBusinessSelect}
                    phone={client?.phone}
                />
            ) : (
            <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-2' onSubmit={handleRequest}>
                <div className='col-span-full'>
                    <h3 className='text-xl font-medium leading-6'>
                        Income Information
                    </h3>
                </div>

                <FormSelect 
                    label='Are you employed with your current employer for at least 1 year?'
                    name='employedCurrently'
                    id="employedCurrently"
                    content={optionsYN}
                    value={employedCurrently}
                    setValue={setEmployedCurrently}
                />
                
                <FormSelect 
                    label='Is your monthly income equal to or greater than GHC2,000 per month?'
                    name='salaryStatus'
                    id="salaryStatus"
                    content={optionsYN}
                    value={salaryStatus}
                    setValue={setSalaryStatus}
                />
                
                <FormSelect 
                    label='Do you receive regular monthly income (Employment or other income)?'
                    name='incomeStatus'
                    id="incomeStatus"
                    content={optionsYN}
                    value={incomeStatus}
                    setValue={setIncomeStatus}
                />

                <FormInput 
                    label='Provide your monthly regular income'
                    name='incomeAmt'
                    id="incomeAmt"
                    type='number'
                    value={incomeAmt}
                    setValue={setIncomeAmt}
                />

                <FormInput 
                    label='Provide your monthly debt expenses'
                    name='debtAmt'
                    id="debtAmt"
                    type='number'
                    value={debtAmt}
                    setValue={setDebtAmt}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6'>
                        Scoring Information
                    </h3>
                </div>
                
                <FormSelect 
                    label='In your 3 months mobile money statement, what is the average number of transactions per month?'
                    name='momo_transactions'
                    id="momo_transactions"
                    content={momoTransactionOpts}
                    value={momo_transactions}
                    setValue={setMomo_transactions}
                />
                
                <FormSelect 
                    label='Do you have at least 20 mobile money transactions in a month?'
                    name='numOfTransactions'
                    id="numOfTransactions"
                    content={optionsYN}
                    value={numOfTransactions}
                    setValue={setNumOfTransactions}
                />
                
                <FormSelect 
                    label='Are your loan payment automated?'
                    name='automatedPaymentsStatus'
                    id="automatedPaymentsStatus"
                    content={optionsYN}
                    value={automatedPaymentsStatus}
                    setValue={setAutomatedPaymentsStatus}
                />
                
                <FormSelect 
                    label='How long have you been operating this business in this location?'
                    name='yearsInOperation'
                    id="yearsInOperation"
                    content={yearsInBusinessOpts}
                    value={yearsInOperation}
                    setValue={setYearsInOperation}
                />

                <FormSelect 
                    label='If you have appropriate insurance coverage, please indicate which of the following you have.'
                    name='typeOfInsurance'
                    id="typeOfInsurance"
                    content={insuranceOpts}
                    value={typeOfInsurance}
                    setValue={setTypeOfInsurance}
                />

                <FormSelect 
                    label='Transaction frequency in a month'
                    name='transFreq'
                    id="transFreq"
                    content={transFrequencyOpts}
                    value={transFreq}
                    setValue={setTransFreq}
                />

                <FormSelect 
                    label='Consistency in transactions'
                    name='consistTrans'
                    id="consistTrans"
                    content={consistentOpts}
                    value={consistTrans}
                    setValue={setConsistTrans}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6'>
                        Credit Behaviour Information
                    </h3>
                </div>

                <FormSelect 
                    label='Do you currently have any outstanding debts or loans?'
                    name='outstandingLoans'
                    id="outstandingLoans"
                    content={optionsYN}
                    value={outstandingLoans}
                    setValue={setOutstandingLoans}
                />

                <FormSelect 
                    label='Have you ever defaulted on or missed a loan payment?'
                    name='loanDefaultStatus'
                    id="loanDefaultStatus"
                    content={optionsYN}
                    value={loanDefaultStatus}
                    setValue={setLoanDefaultStatus}
                />

                <FormSelect 
                    label='How many times did you miss payment on your loan?'
                    name='defaultedLoanStatus'
                    id="defaultedLoanStatus"
                    content={missedPaymentOptions}
                    value={defaultedLoanStatus}
                    setValue={setDefaultedLoanStatus}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6'>
                        Bills Payment Information
                    </h3>
                </div>

                <FormSelect 
                    label='What are the length of history of your bills payment history?'
                    name='billsHistory'
                    id="billsHistory"
                    content={billsHistoryOpts}
                    value={billsHistory}
                    setValue={setBillsHistory}
                />

                <FormSelect 
                    label='Have you ever defaulted or missed a bill payment?'
                    name='billDefaultStatus'
                    id="billDefaultStatus"
                    content={optionsYN}
                    value={billDefaultStatus}
                    setValue={setBillDefaultStatus}
                />

                <FormSelect 
                    label='How many times did you miss payment on your bills?'
                    name='billsHistoryStatus'
                    id="billsHistoryStatus"
                    content={missedPaymentOptions}
                    value={billsHistoryStatus}
                    setValue={setBillsHistoryStatus}
                />

                <FormSelect 
                    label='Do you have 3 recent months loan payment history?'
                    name='loanPaymentHistory'
                    id="loanPaymentHistory"
                    content={optionsYN}
                    value={loanPaymentHistory}
                    setValue={setLoanPaymentHistory}
                />

                <FormSelect 
                    label='Do you have 3 recent months bills payment history?'
                    name='billPaymentHistory'
                    id="billPaymentHistory"
                    content={optionsYN}
                    value={billPaymentHistory}
                    setValue={setBillPaymentHistory}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6'>
                        Savings Habit Information
                    </h3>
                </div>

                <div className="col-span-full">
                    <FormSelect 
                        label='Do you have a savings account held with a financial institution, pension or investment company that you can use for emergencies, such as sickness or job loss? Please note that regular savings and checking accounts may not meet this requirement.'
                        name='savingHabits'
                        id="savingHabits"
                        content={optionsYN}
                        value={savingHabits}
                        setValue={setSavingHabits}
                    />
                </div>

                <FormInput 
                    label='Month 1'
                    name='savingsFreq1'
                    id="savingsFreq1"
                    type='number'
                    value={savingsFreq1}
                    setValue={setSavingsFreq1}
                />

                <FormInput 
                    label='Month 2'
                    name='savingsFreq2'
                    id="savingsFreq2"
                    type='number'
                    value={savingsFreq2}
                    setValue={setSavingsFreq2}
                />

                <FormInput 
                    label='Month 3'
                    name='savingsFreq3'
                    id="savingsFreq3"
                    type='number'
                    value={savingsFreq3}
                    setValue={setSavingsFreq3}
                />

                <div className='col-span-full md:col-span-2'>
                    <FormSelect 
                        label='Do you regularly save a minimum of 5% of your monthly income for emergency purposes or future financial goals?'
                        name='eligibleSavingsHabit'
                        id="eligibleSavingsHabit"
                        content={optionsYN}
                        value={eligibleSavingsHabit}
                        setValue={setEligibleSavingsHabit}
                    />
                </div>

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6'>
                        Loan Information
                    </h3>
                </div>

                <FormInput 
                    label='Loan amount'
                    name='amountReq'
                    id="amountReq"
                    type='number'
                    value={amountReq}
                    setValue={setAmountReq}
                />

                <FormSelect 
                    label='What is the purpose of the loan?'
                    name='purpose'
                    id="purpose"
                    content={purposeOfLoanOpts}
                    value={purpose}
                    setValue={setPurpose}
                />

                <FormSelect 
                    label='What is the desired payment plan?'
                    name='paymentPlan'
                    id="paymentPlan"
                    content={paymentPlanOpts}
                    value={paymentPlan}
                    setValue={setPaymentPlan}
                />

                <FormSelect 
                    label='What is the desired payment duration?'
                    name='paymentDuration'
                    id="paymentDuration"
                    content={paymentDurationOpts}
                    value={paymentDuration}
                    setValue={setPaymentDuration}
                />

                <FormInput 
                    label='Provide your source of income for loan repayment'
                    name='repaySource'
                    id="repaySource"
                    type='text'
                    value={repaySource}
                    setValue={setRepaySource}
                />

                <FormSelect 
                    label='What is the frequency of your income?'
                    name='freqIncome'
                    id="freqIncome"
                    content={incomeFrequencyOpts}
                    value={freqIncome}
                    setValue={setFreqIncome}
                />

                <FormSelect 
                    label='Select your preferred automatic loan repayment method'
                    name='autoDeduction'
                    id="autoDeduction"
                    content={automaticPaymentOpts}
                    value={autoDeduction}
                    setValue={setAutoDeduction}
                />

                <div>
                    <label htmlFor="startDate" className='form-label'>
                        Select loan start date
                    </label>
                    <input 
                        type="date"
                        name={"startDate"}
                        id={"startDate"}
                        className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        min={new Date().toISOString().split('T')[0]}
                        max={nextMonthStartDate()}
                    />
                </div>

                <FormSelect 
                    label='Kindly select a relationship manager'
                    name='relManager'
                    id="relManager"
                    content={relationshipManagers}
                    value={relManager}
                    setValue={setRelManager}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6'>
                        Guarantor Information
                    </h3>
                </div>

                <FormInput 
                    label="Guarantor's first name"
                    name='guarantorFirstName'
                    id="guarantorFirstName"
                    type='text'
                    value={guarantorFirstName}
                    setValue={setGuarantorFirstName}
                />

                <FormInput 
                    label="Guarantor's last name"
                    name='guarantorLastName'
                    id="guarantorLastName"
                    type='text'
                    value={guarantorLastName}
                    setValue={setGuarantorLastName}
                />

                <FormInput 
                    label="Guarantor's phone number"
                    name='guarantorPhone'
                    id="guarantorPhone"
                    type='text'
                    value={guarantorPhone}
                    setValue={setGuarantorPhone}
                />

                <FormInput 
                    label="Guarantor's address"
                    name='guarantorAddress'
                    id="guarantorAddress"
                    type='text'
                    value={guarantorAddress}
                    setValue={setGuarantorAddress}
                />

                <FormInput 
                    label="Your relationship to guarantor"
                    name='guarantorRelationship'
                    id="guarantorRelationship"
                    type='text'
                    value={guarantorRelationship}
                    setValue={setGuarantorRelationship}
                />

                <FormInput 
                    label="Guarantor's GhanaCard number"
                    name='guarantorIdNumber'
                    id="guarantorIdNumber"
                    type='text'
                    value={guarantorIdNumber}
                    setValue={setGuarantorIdNumber}
                />

                <FormInput 
                    label="Guarantor's digital address"
                    name='guarantorDigitalAddress'
                    id="guarantorDigitalAddress"
                    type='text'
                    value={guarantorDigitalAddress}
                    setValue={setGuarantorDigitalAddress}
                />

                <div>
                    <label htmlFor="guarantorDob" className='form-label'>
                        Guarantor's date of birth
                    </label>
                    <input 
                        type="date"
                        name={"guarantorDob"}
                        id={"guarantorDob"}
                        className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                        value={guarantorDob}
                        onChange={e => setGuarantorDob(e.target.value)}
                        min={"1930-01-01"}
                        max={new Date().toISOString().split('T')[0]}
                    />
                </div>

                <NextOfKin 
                    nextOfKin={nextOfKin}
                    setNextOfKin={setNextOfKin}
                    nextOfKinPhone={nextOfKinPhone}
                    setNextOfKinPhone={setNextOfKinPhone}
                    nextOfKinAddress={nextOfKinAddress}
                    setNextOfKinAddress={setNextOfKinAddress}
                    nextOfKinRelationship={nextOfKinRelationship}
                    setNextOfKinRelationship={setNextOfKinRelationship}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6'>
                        Files to upload
                    </h3>
                </div>

                <FormUpload 
                    label='Upload proof of income'
                    name='incomeProof'
                    id="incomeProof"
                    value={incomeProof}
                    setValue={setIncomeProof}
                />

                <FormUpload 
                    label='Upload proof of any of the following: (1) Existing customer as Guarantor (2) An agent at the port (3) Joint agreement to get proceeds from contract paid directly to CedisPay.'
                    name='guarAgreeSigned'
                    id="guarAgreeSigned"
                    value={guarAgreeSigned}
                    setValue={setGuarAgreeSigned}
                />

                <FormUpload 
                    label='Upload bank statement or momo statement'
                    name='bankOrMomoStatement'
                    id="bankOrMomoStatement"
                    value={bankOrMomoStatement}
                    setValue={setBankOrMomoStatement}
                />

                <div className="col-span-full mt-10">
                    <h3 className='text-xl font-medium leading-6'>
                        Attach other files 
                    </h3>
                </div>

                <OtherDocsUpload 
                    setValue={setOtherDocs}
                />

                <LoanAuth />

                <FormInput 
                    label='Signed Name'
                    name='signedName'
                    id="signedName"
                    type='text'
                    value={signedName}
                    disabled={true}
                />

                <div className="col-span-full mt-2">
                    <div className="inline-flex items-center gap-2">
                        <Button 
                            btnText={"Check eligibility"}
                            btnType={"submit"}
                            btnClasses={"text-sm bg-light-blue px-3 py-2 rounded-md text-white"}
                            processing={processing}
                        />
                    </div>
                </div>
            </form>
            )}

            <Modal isOpen={isOpen} closeModal={closeModal}>
                <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 flex items-center mb-4">
                        Pre-Check Status: <span className={`ml-1 px-3 py-1.5 text-white font-medium rounded-full text-xs capitalize ${preCheckData?.status === 'accepted' ? 'bg-emerald-400' : preCheckData?.status === 'rejected' ? 'bg-red-400' : ''}`}>{preCheckData?.status}</span>
                    </Dialog.Title>
                    <EligCheck 
                        status={preCheckData?.status}
                        data={preCheckData}
                        loanType={"capitalme"}
                        closeModal={closeModal}
                    />
                </Dialog.Panel>
            </Modal>
        </>
    )
}

export default Capitalme