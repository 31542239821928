import React from 'react'
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isolateStaff } from '../../../slices/staffSlice';

function Member({ item }) {

    const dispatch = useDispatch();

    const passUserId = (event, item) => {
        dispatch(isolateStaff(item))
    }

    return (
        <>
        <tr className="px-4 py-6 border-b hover:bg-gray-100 text-sm">
            <td className='px-4 py-3'>
                {`${item?.otherNames} ${item?.surname}`}
            </td>
            <td className='px-4 py-3'>
                {item?.email}
            </td>
            <td className='px-4 py-3'>
                {item?.phone}
            </td>
            <td className='px-4 py-3'>
                {item?.approved ? 
                    <span className='text-emerald-700 text-xs px-2 py-1 rounded-full bg-emerald-100'>
                        approved
                    </span> 
                    : 
                    <span className='text-red-700 text-xs px-2 py-1 rounded-full bg-red-100'>
                        not approved
                    </span>
                }
            </td>
            <td className='px-4 py-3 inline-flex items-center space-x-4'>
                <Link onClick={event => passUserId(event, item?._id)} to="/admin/staff/edit" className='cursor-pointer hover:text-gold-500'>
                    View
                </Link>
            </td>
        </tr>
        </>
    )
}

export default Member