import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function Users({ active, inactive }) {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Number of customer accounts',
            },
        },
        // indexAxis: 'x',
        
    };

    const labels = ['Accounts'];

    const data = {
        type : 'bar',
        labels,
        datasets: [
            {
                label: 'Verified',
                data: [active],
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                borderColor: 'rgba(75, 192, 192, 0.6)',
                borderWidth: 2,
                borderSkipped: 'bottom',
                borderRadius: 5,
            },
            {
                label: 'Not Verified',
                data: [inactive],
                backgroundColor: 'rgba(237, 27, 36, 0.5)',
                borderColor: 'rgba(237, 27, 36, 0.6)',
                borderWidth: 2,
                borderSkipped: 'bottom',
                borderRadius: 5,
            }
        ],
    };

    return (
        <Bar options={options} data={data} />
    )
}

export default Users