import { useState } from 'react';
import { FormSelect, FormInput, FormUpload, Button, Modal, OtherDocsUpload } from "../../components";
import { optionsYN, missedPaymentOptions, billPaymentOptions, purposeOfLoanOpts, paymentPlanOpts, paymentDurationOpts, incomeFrequencyOpts, automaticPaymentOpts, relationshipManagers, getDateAfterTimeframe, transPerMonthOpts, minimumAmtPerTransOpts, averageBalanceOpts, incomeConsistencyOpts, plocYearsInBusinessOpts, nextMonthStartDate } from '../../utils/constants';
import LoanAuth from "./LoanAuth";
import { preCheckLoanEligibility } from '../../utils/loanProcessing';
import { Dialog } from '@headlessui/react';
import EligCheck from './EligCheck';
import { useDispatch } from 'react-redux';
import { show } from '../../slices/toastSlice';
import NextOfKin from './NextOfKin';

function CedisCredit({ client }) {

    let [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        setIsOpen(false)
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const [incomeAmt, setIncomeAmt] = useState('');
    const [debtAmt, setDebtAmt] = useState('');
    const [yearsInOperation, setYearsInOperation] = useState('');
    const [averageMomoBalance, setAverageMomoBalance] = useState('');
    const [incomeConsist, setIncomeConsist] = useState('');
    const [loanPaymentHistory, setLoanPaymentHistory] = useState('');
    const [billPaymentHistory, setBillPaymentHistory] = useState('');
    const [billsOption, setBillsOption] = useState('');
    const [loanHistoryStatus, setLoanHistoryStatus] = useState('');
    const [loanDefaultStatus, setLoanDefaultStatus] = useState('');
    const [defaultedLoanStatus, setDefaultedLoanStatus] = useState('');
    const [uptodateBillsStatus, setUptodateBillsStatus] = useState('');
    const [numTransPerMonth, setNumTransPerMonth] = useState('');
    const [minAmtPerMonth, setMinAmtPerMonth] = useState('');
    const [automatedPaymentsStatus, setAutomatedPaymentsStatus] = useState('');
    const [purpose, setPurpose] = useState('');
    const [paymentPlan, setPaymentPlan] = useState('');
    const [paymentDuration, setPaymentDuration] = useState('');
    const [repaySource, setRepaySource] = useState('');
    const [freqIncome, setFreqIncome] = useState('');
    const [autoDeduction, setAutoDeduction] = useState('');
    const [incomeProof, setIncomeProof] = useState('');
    const [residenceProof, setResidenceProof] = useState('');
    const [bankOrMomoStatement, setBankOrMomoStatement] = useState('');
    const [otherDocs, setOtherDocs] = useState([]);
    const [startDate, setStartDate] = useState(getDateAfterTimeframe('daily'));
    const [relManager, setRelManager] = useState('');
    const [guarantorFirstName, setGuarantorFirstName] = useState('');
    const [guarantorLastName, setGuarantorLastName] = useState('');
    const [guarantorPhone, setGuarantorPhone] = useState('');
    const [guarantorAddress, setGuarantorAddress] = useState('');
    const [guarantorRelationship, setGuarantorRelationship] = useState('');
    const guarantorIdType = 'GhanaCard';
    const [guarantorIdNumber, setGuarantorIdNumber] = useState('');
    const [guarantorDigitalAddress, setGuarantorDigitalAddress] = useState('');
    const [guarantorDob, setGuarantorDob] = useState('');
    const [nextOfKin, setNextOfKin] = useState('');
    const [nextOfKinPhone, setNextOfKinPhone] = useState('');
    const [nextOfKinAddress, setNextOfKinAddress] = useState('');
    const [nextOfKinRelationship, setNextOfKinRelationship] = useState('');
    const signedName = client?.name;
    const [processing, setProcessing] = useState(false);
    const [preCheckData, setPreCheckData] = useState({});


    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        let data = { phone: client?.phone, incomeAmt, debtAmt, yearsInOperation, averageMomoBalance, incomeConsist, loanPaymentHistory, billPaymentHistory, billsOption, loanHistoryStatus, loanDefaultStatus, defaultedLoanStatus, uptodateBillsStatus, numTransPerMonth, minAmtPerMonth, automatedPaymentsStatus, purpose, paymentPlan, paymentDuration, repaySource, freqIncome, autoDeduction, incomeProof, residenceProof, bankOrMomoStatement, otherDocs, relManager, startDate, guarantorFirstName, guarantorLastName, guarantorPhone, guarantorAddress, guarantorRelationship, guarantorIdType, guarantorIdNumber, guarantorDigitalAddress, guarantorDob, nextOfKin, nextOfKinPhone, nextOfKinAddress, nextOfKinRelationship, signedName }

        // check to see if files have been uploaded
        let incomeFile = document.getElementById('incomeProof');
        let residenceFile = document.getElementById('residenceProof');
        let bankFile = document.getElementById('bankOrMomoStatement');

        if(!incomeFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach proof of income"
            }))
            return;
        }

        if(!residenceFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach proof of residence"
            }))
            return;
        }

        if(!bankFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach bank or momo statement"
            }))
            return;
        }

        // only submit if loan passes pre-check
        let response = await preCheckLoanEligibility("cediscredit", data);
        setPreCheckData({ ...response, data });

        setTimeout(() => {
            setProcessing(false);
            openModal();
        },500);
    }

    return (
        <>
        <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-2' encType={'multipart/form-data'} onSubmit={handleRequest}>

            <div className='col-span-full'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Income Information
                </h3>
            </div>

            <FormInput 
                label='Provide your monthly regular income'
                name='incomeAmt'
                id="incomeAmt"
                type='number'
                value={incomeAmt}
                setValue={setIncomeAmt}
            />

            <FormInput 
                label='Provide your monthly debt expenses'
                name='debtAmt'
                id="debtAmt"
                type='number'
                value={debtAmt}
                setValue={setDebtAmt}
            />

            <FormSelect 
                label='How long have you been operating this business in this location?'
                name='yearsInOperation'
                id="yearsInOperation"
                content={plocYearsInBusinessOpts}
                value={yearsInOperation}
                setValue={setYearsInOperation}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Momo Statemennt Information
                </h3>
            </div>

            <FormSelect 
                label='What is your average 3 months mobile money balance?'
                name="averageMomoBalance"
                id="averageMomoBalance"
                content={averageBalanceOpts}
                value={averageMomoBalance}
                setValue={setAverageMomoBalance}
            />

            <FormSelect 
                label='How would you describe the consistency of your mobile money transactions in the past 3 months?'
                name="incomeConsist"
                id="incomeConsist"
                content={incomeConsistencyOpts}
                value={incomeConsist}
                setValue={setIncomeConsist}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Loan &amp; Bill Payment Behaviour Information
                </h3>
            </div>

            <FormSelect 
                label='Do you have 3 recent months loan payment history?'
                name='loanPaymentHistory'
                id="loanPaymentHistory"
                content={optionsYN}
                value={loanPaymentHistory}
                setValue={setLoanPaymentHistory}
            />

            <FormSelect 
                label='Do you have 3 recent months bills payment history?'
                name='billPaymentHistory'
                id="billPaymentHistory"
                content={optionsYN}
                value={billPaymentHistory}
                setValue={setBillPaymentHistory}
            />

            <FormSelect 
                label='What are the bills that you are responsible for paying?'
                name='billsOption'
                id="billsOption"
                content={billPaymentOptions}
                value={billsOption}
                setValue={setBillsOption}
            />

            <FormSelect 
                label='What is the length of history of your loan payment?'
                name="loanHistoryStatus"
                id="loanHistoryStatus"
                content={missedPaymentOptions}
                value={loanHistoryStatus}
                setValue={setLoanHistoryStatus}
            />

            <FormSelect 
                label='Have you ever defaulted on or missed a loan payment?'
                name='loanDefaultStatus'
                id="loanDefaultStatus"
                content={optionsYN}
                value={loanDefaultStatus}
                setValue={setLoanDefaultStatus}
            />

            <FormSelect 
                label='How many times did you miss payment on your loan or bill payments?'
                name='defaultedLoanStatus'
                id="defaultedLoanStatus"
                content={missedPaymentOptions}
                value={defaultedLoanStatus}
                setValue={setDefaultedLoanStatus}
            />

            <FormSelect 
                label='Are you currently up to date on payment of your electric bill?'
                name="uptodateBillsStatus"
                id="uptodateBillsStatus"
                content={optionsYN}
                value={uptodateBillsStatus}
                setValue={setUptodateBillsStatus}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Scoring Information
                </h3>
            </div>

            <FormSelect 
                label='How many mobile money transactions do you make on average per month?'
                name="numTransPerMonth"
                id="numTransPerMonth"
                content={transPerMonthOpts}
                value={numTransPerMonth}
                setValue={setNumTransPerMonth}
            />

            <FormSelect 
                label='What is the minimum amount per transaction that you typically make in a month?'
                name="minAmtPerMonth"
                id="minAmtPerMonth"
                content={minimumAmtPerTransOpts}
                value={minAmtPerMonth}
                setValue={setMinAmtPerMonth}
            />

            <FormSelect 
                label='Do you have automated loan / bill payments set up?'
                name='automatedPaymentsStatus'
                id="automatedPaymentsStatus"
                content={optionsYN}
                value={automatedPaymentsStatus}
                setValue={setAutomatedPaymentsStatus}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Loan Information
                </h3>
            </div>

            <FormSelect 
                label='What is the purpose of the loan?'
                name='purpose'
                id="purpose"
                content={purposeOfLoanOpts}
                value={purpose}
                setValue={setPurpose}
            />

            <FormSelect 
                label='What is the desired payment plan?'
                name='paymentPlan'
                id="paymentPlan"
                content={paymentPlanOpts.slice(0, 1)}
                value={paymentPlan}
                setValue={setPaymentPlan}
            />

            <FormSelect 
                label='What is the desired payment duration?'
                name='paymentDuration'
                id="paymentDuration"
                content={paymentDurationOpts.slice(0, 3)}
                value={paymentDuration}
                setValue={setPaymentDuration}
            />

            <FormInput 
                label='Provide your source of income for loan repayment'
                name='repaySource'
                id="repaySource"
                type='text'
                value={repaySource}
                setValue={setRepaySource}
            />

            <FormSelect 
                label='What is the frequency of your income?'
                name='freqIncome'
                id="freqIncome"
                content={incomeFrequencyOpts}
                value={freqIncome}
                setValue={setFreqIncome}
            />

            <FormSelect 
                label='Please select your preferred automatic loan repayment method for your CedisPay loan'
                name='autoDeduction'
                id="autoDeduction"
                content={automaticPaymentOpts.slice(2, 3)}
                value={autoDeduction}
                setValue={setAutoDeduction}
            />

            <div>
                <label htmlFor="startDate" className='form-label'>
                    Select loan start date
                </label>
                <input 
                    type="date"
                    name={"startDate"}
                    id={"startDate"}
                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    min={new Date().toISOString().split('T')[0]}
                    max={nextMonthStartDate()}
                />
            </div>

            <FormSelect 
                label='Kindly select a relationship manager'
                name='relManager'
                id="relManager"
                content={relationshipManagers}
                value={relManager}
                setValue={setRelManager}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Guarantor Information
                </h3>
            </div>

            <FormInput 
                label="Guarantor's first name"
                name='guarantorFirstName'
                id="guarantorFirstName"
                type='text'
                value={guarantorFirstName}
                setValue={setGuarantorFirstName}
            />

            <FormInput 
                label="Guarantor's last name"
                name='guarantorLastName'
                id="guarantorLastName"
                type='text'
                value={guarantorLastName}
                setValue={setGuarantorLastName}
            />

            <FormInput 
                label="Guarantor's phone number"
                name='guarantorPhone'
                id="guarantorPhone"
                type='text'
                value={guarantorPhone}
                setValue={setGuarantorPhone}
            />

            <FormInput 
                label="Guarantor's address"
                name='guarantorAddress'
                id="guarantorAddress"
                type='text'
                value={guarantorAddress}
                setValue={setGuarantorAddress}
            />

            <FormInput 
                label="Your relationship to guarantor"
                name='guarantorRelationship'
                id="guarantorRelationship"
                type='text'
                value={guarantorRelationship}
                setValue={setGuarantorRelationship}
            />

            <FormInput 
                label="Guarantor's GhanaCard number"
                name='guarantorIdNumber'
                id="guarantorIdNumber"
                type='text'
                value={guarantorIdNumber}
                setValue={setGuarantorIdNumber}
            />

            <FormInput 
                label="Guarantor's digital address"
                name='guarantorDigitalAddress'
                id="guarantorDigitalAddress"
                type='text'
                value={guarantorDigitalAddress}
                setValue={setGuarantorDigitalAddress}
            />

            <div>
                <label htmlFor="guarantorDob" className='form-label'>
                    Guarantor's date of birth
                </label>
                <input 
                    type="date"
                    name={"guarantorDob"}
                    id={"guarantorDob"}
                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                    value={guarantorDob}
                    onChange={e => setGuarantorDob(e.target.value)}
                    min={"1930-01-01"}
                    max={new Date().toISOString().split('T')[0]}
                />
            </div>

            <NextOfKin 
                nextOfKin={nextOfKin}
                setNextOfKin={setNextOfKin}
                nextOfKinPhone={nextOfKinPhone}
                setNextOfKinPhone={setNextOfKinPhone}
                nextOfKinAddress={nextOfKinAddress}
                setNextOfKinAddress={setNextOfKinAddress}
                nextOfKinRelationship={nextOfKinRelationship}
                setNextOfKinRelationship={setNextOfKinRelationship}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Files to upload
                </h3>
            </div>

            <FormUpload 
                label='Upload proof of income'
                name='incomeProof'
                id="incomeProof"
                value={incomeProof}
                setValue={setIncomeProof}
            />

            <FormUpload 
                label='Upload proof of residence'
                name='residenceProof'
                id="residenceProof"
                value={residenceProof}
                setValue={setResidenceProof}
            />

            <FormUpload 
                label='Upload bank statement or momo statement'
                name='bankOrMomoStatement'
                id="bankOrMomoStatement"
                value={bankOrMomoStatement}
                setValue={setBankOrMomoStatement}
            />

            <div className="col-span-full mt-10">
                <h3 className='text-xl font-medium leading-6'>
                    Attach other files 
                </h3>
            </div>

            <OtherDocsUpload 
                setValue={setOtherDocs}
            />

            <LoanAuth />

            <FormInput 
                label='Signed Name'
                name='signedName'
                id="signedName"
                type='text'
                value={signedName}
                disabled={true}
            />

            <div className="col-span-full mt-2">
                <div className="inline-flex items-center gap-2">
                    <Button 
                        btnText={"Check eligibility"}
                        btnType={"submit"}
                        btnClasses={"text-sm bg-light-blue px-3 py-2 rounded-md text-white"}
                        processing={processing}
                    />
                </div>
            </div>

        </form>

        <Modal isOpen={isOpen} closeModal={closeModal}>
            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 flex items-center mb-4">
                    Pre-Check Status: <span className={`ml-1 px-3 py-1.5 text-white font-medium rounded-full text-xs capitalize ${preCheckData?.status === 'accepted' ? 'bg-emerald-400' : preCheckData?.status === 'rejected' ? 'bg-red-400' : ''}`}>{preCheckData?.status}</span>
                </Dialog.Title>
                <EligCheck 
                    status={preCheckData?.status}
                    data={preCheckData}
                    loanType={"cediscredit"}
                    closeModal={closeModal}
                />
            </Dialog.Panel>
        </Modal>
        </>
    )
}

export default CedisCredit