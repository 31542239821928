import { useState } from 'react';
import { Topbar, Sidebar, FormSelect, MomoUpload, Button } from "../../components";
import { networkProviders } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { show } from '../../slices/toastSlice';
import { selectAccessToken } from '../../slices/staffSlice';
import { uploadFileRequest } from '../../Requests/getRequests';
 
function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const [network, setNetwork] = useState('');
    const [momoStatement, setMomoStatement] = useState('');
    const [processing, setProcessing] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        // check to see if files have been uploaded
        let momoStatementFile = document.getElementById("momoStatement");

        if (!momoStatementFile.files.length > 0) {
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach momo statement file"
            }))
            return;
        }

        // send request
        let url = `/loans/staff/momo-analyzer`;
        let headers = {
            "authorization" : `${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }

        const formData = new FormData();
        formData.append('network', network);
        formData.append('momoStatement', momoStatement);

        let res = await uploadFileRequest(url, headers, formData);
        console.log(res);
        setProcessing(false);
    }

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container space-y-6'>
                    <h3 className='text-3xl font-medium leading-6 text-display mb-5'>
                        Momo Analyzer
                    </h3>

                    <div className='my-16'>
                        <form className='grid grid-cols-1 md:grid-cols-2 gap-6 px-2' encType={'multipart/form-data'} onSubmit={handleRequest}>

                            <FormSelect 
                                label='Please select the mobile network'
                                name='network'
                                id="network"
                                content={networkProviders}
                                value={network}
                                setValue={setNetwork}
                                required={true}
                            />

                            <MomoUpload 
                                label='Select the momo statement'
                                name='momoStatement'
                                id="momoStatement"
                                setValue={setMomoStatement}
                            />

                            <Button 
                                btnText={"Upload"}
                                btnType={"submit"}
                                btnClasses={"text-sm bg-light-blue px-3 py-2 rounded-md text-white"}
                                processing={processing}
                            />

                        </form>
                    </div>

                </section>
            </main>
        </div>
    )
}

export default Index