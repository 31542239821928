import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    state : false,
    message : "",
    type : "",
}

export const toastSlice = createSlice({
    name : "toast",
    initialState,
    reducers : {
        show : (state, action) => {
            state.state = action.payload.state;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        hide : (state) => {
            state.state = false;
            state.message = "";
            state.type = "";
        },
    }
});


export const { show, hide } = toastSlice.actions;

export default toastSlice.reducer;