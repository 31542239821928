import { OtherReports, AllReports } from "../../components";

function Reports() {

    return (
        <section className='section-container space-y-10'>
            <h3 className='text-3xl font-medium leading-6 text-display'>Generate Reports</h3>

            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table className='min-w-full'>
                        <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50'>
                            <tr>
                                <th scope="col" className='px-4 py-3 md:w-[40%]'>Report Type</th>
                                <th scope="col" className='px-4 py-3 md:w-[40%]'>Date (End)</th>
                                <th scope="col" className='px-4 py-3 md:w-[20%]'>Action / Result</th>
                            </tr>
                        </thead>

                        <tbody>
                            <AllReports />
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table className='min-w-full'>
                        <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50'>
                            <tr>
                                <th scope="col" className='px-4 py-3 md:w-[25%]'>Report Type</th>
                                <th scope="col" className='px-4 py-3 md:w-[25%]'>From Date</th>
                                <th scope="col" className='px-4 py-3 md:w-[25%]'>To Date</th>
                                <th scope="col" className='px-4 py-3 md:w-[25%]'>Action / Result</th>
                            </tr>
                        </thead>

                        <tbody>
                            <OtherReports />
                        </tbody>
                    </table>
                </div>
            </div>

        </section>
    )
}

export default Reports