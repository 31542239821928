import React from 'react'

function Permission({ item }) {
    return (
        <>
        <tr className="px-4 py-6 border-b hover:bg-gray-100">
            <td className='px-4 py-3'>
                {item?.name}
            </td>
            <td className='px-4 py-3'>
                {item?.description}
            </td>
            <td className='px-4 py-3'>
                {item?.slug}
            </td>
            <td className='px-4 py-3 inline-flex items-center space-x-4'>
                {/* <Link onClick={event => passUserId(event, item?.staffId)} to="/admin/staff/edit" className='cursor-pointer hover:text-gold-500'>
                    Edit
                </Link> */}
                <span>
                    Suspend
                </span>
            </td>
        </tr>
        </>
    )
}

export default Permission