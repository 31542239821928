import { useEffect, useState } from 'react';
import { Button, Reversal } from "../../../components";
import { authStaff, getFirstFiveDaysOfMonth, getAllDates, repaymentAmtLimit } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { fetchRequests } from '../../../Requests/getRequests';
import { useNavigate } from 'react-router-dom';
import { show } from '../../../slices/toastSlice';

function Repay({ loan }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [transactionType, setTranasctionType] = useState('repay');
    const [transactionID, setTransactionID] = useState('');
    const [transactionDate, setTransactionDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [amount, setAmount] = useState('');
    const [penalRateAmount, setPenalRateAmount] = useState('');
    const [remarks, setRemarks] = useState('');
    const [process, setProcess] = useState(false);

    // for reversal modal
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    const closeModal = () => {
        setIsOpen(!isOpen);
    }

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        if(amount >= repaymentAmtLimit){
            dispatch(show({
                type : "error",
                state : true,
                message : "Amount cannot be more than the repayment limit of 30,000"
            }))
            setProcess(false);
            return;
        }

        let data = {
            _id : loan?._id,
            type : loan?.type.toLowerCase(),
            transactionType,
            transactionID : transactionID.replace(/[^a-zA-Z0-9]/g, ''),
            transactionDate,
            amount,
            penalRateAmount,
            remarks
        }

        let url = `/loans/staff/transactions`;
        const headers = authStaff(accessToken);

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setProcess(false);

        if(res.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        if(res?.path){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.message
            }))
            navigate('/completed-loans');
            return;
        }

        if(res?.message){
            dispatch(show({
                type : "success",
                state : true,
                message : res?.message
            }))
        }
        
        // reset form
        setTranasctionType('repay');
        setTransactionID('');
        setTransactionDate('');
        setAmount('');

        // refresh page
        setTimeout(() => {
            window.location.reload();
        }, 1500)
    }

    useEffect(() => {   

        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let today = currentDate.getDate();
        let dates = getFirstFiveDaysOfMonth(year, month);
        let allDates = getAllDates(dates);

        if(allDates.includes(today)){
            setMinDate(new Date(year, month - 1, 1).toISOString().split("T")[0]);
            setMaxDate(currentDate.toISOString().split("T")[0]);
        } else {
            setMinDate(currentDate.toISOString().split("T")[0]);
            setMaxDate(currentDate.toISOString().split("T")[0]);
        }

    }, [setMinDate, setMaxDate])

    return (
        <form className='grid grid-cols-1 md:grid-cols-3 gap-4 rounded-md py-4' onSubmit={handleRequest}>

            <div>
                <label htmlFor="transactionType" className='form-label'>Transaction type:</label>
                <select className='form-input' id='transactionType' name='transactionType' required onChange={(e) => setTranasctionType(e.target.value)} defaultValue={transactionType}>
                    <option value="" disabled>Select transaction type</option>
                    <option value="repay">Repayment</option>
                    <option value="writeoff">Write-off</option>
                </select>
            </div>

            <div>
                <label htmlFor="transactionID" className='form-label'>Transaction ID:</label>
                <input type="text" name="transactionID" id="transactionID" className='form-input' required value={transactionID} onChange={(e) => setTransactionID(e.target.value)} />
            </div>

            <div>
                <label htmlFor="transactionDate" className='form-label'>Transaction date:</label>
                <input type="date" name="transactionDate" id="transactionDate" className='form-input' min={minDate} max={maxDate} required value={transactionDate} onChange={(e) => setTransactionDate(e.target.value)} />
            </div>

            <div>
                <label htmlFor="amount" className='form-label'>Amount:</label>
                <input type="number" name="amount" id="amount" className='form-input' required value={amount} onChange={(e) => setAmount(e.target.value)} />
            </div>

            <div>
                <label htmlFor="penalRateAmount" className='form-label'>Penal Rate Amount:</label>
                <input type="number" name="penalRateAmount" id="penalRateAmount" className='form-input' required value={penalRateAmount} onChange={(e) => setPenalRateAmount(e.target.value)} />
            </div>

            <div>
                <label htmlFor="remarks">Remarks:</label>
                <input type="text" name="remarks" id="remarks" className='form-input' required value={remarks} onChange={(e) => setRemarks(e.target.value)} placeholder='Indicate if transaction is past or present' />
            </div>

            <div className='col-span-full space-x-4'>
                <Button 
                    btnText={"Submit"}
                    btnClasses={"form-button w-auto px-6"}
                    btnType="submit"
                    processing={process}
                />

                <Button 
                    btnText={"Reversal Request"}
                    btnClasses={"form-button-default w-auto px-6"}
                    btnType="button"
                    btnFunction={openModal}
                />
            </div>

            <div className='hidden'>
                <Reversal 
                    isOpen={isOpen}
                    closeModal={closeModal}
                    loanId={loan?.loanId}
                    loanType={loan?.type}
                    phone={loan?.phone}
                    client={loan?.client?.otherNames + ' ' + loan?.client?.surname}
                />
            </div>
        </form>
    )
}

export default Repay