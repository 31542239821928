import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isolateStaff } from '../../../slices/staffSlice';
import { ClientToggle } from "../../../components";
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { getDateAndTime } from "../../../utils/constants";

function Debtor({ item, setClientList }) {

    const dispatch = useDispatch();

    const { date, time } = getDateAndTime(item?.createdAt);

    const passUserId = (event, item) => {
        dispatch(isolateStaff(item))
    }

    return (
        <>
        <tr className="px-4 py-6 border-b hover:bg-gray-100 text-sm">
            <td className='px-4 py-3 text-ellipsis overflow-hidden flex items-center'>
                <span>{`${item?.otherNames} ${item?.surname}`}</span>
                {item?.isVerified && 
                    <span>
                        <CheckBadgeIcon 
                            className='ml-3 w-5 h-5 text-blue-400 cursor-pointer'
                        />
                    </span>
                }
            </td>
            <td className='px-4 py-3 text-ellipsis overflow-hidden'>
                {item?.email}
            </td>
            <td className='px-4 py-3'>
                {item?.phone}
            </td>
            {/* <td className='px-4 py-3'>
                {item?.isVerified ? 
                    <span className="text-emerald-700 text-xs px-2.5 py-1 rounded-full bg-emerald-100">
                        verified
                    </span>
                    :
                    <span className="text-red-700 text-xs px-2.5 py-1 rounded-full bg-red-100">
                        not verified
                    </span>
                }
            </td> */}
            <td className='px-4 py-3 font-medium'>
                {date}, {time}
            </td>
            <td className='px-4 py-3 inline-flex items-center space-x-4'>
                <Link onClick={event => passUserId(event, item?._id || item?._id)} to="/admin/clients/edit" className='cursor-pointer hover:text-gold-500'>
                    View
                </Link>

                <ClientToggle 
                    _id={item?._id}
                    status={item?.isDisabled}
                    setClientList={setClientList}
                />
            </td>
        </tr>
        </>
    )
}

export default Debtor