import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { Button, FormInput } from "../../../components";
import { authStaff } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { fetchRequests } from '../../../Requests/getRequests';
import { show } from '../../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Reversal({ isOpen, closeModal, loanId, loanType, phone, client }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [oldTransID, setOldTransID] = useState("");
    const [oldDate, setOldDate] = useState("");
    const [oldAmount, setOldAmount] = useState("");
    const [newTransID, setNewTransID] = useState("");
    const [newDate, setNewDate] = useState("");
    const [newAmount, setNewAmount] = useState("");
    const [comment, setComment] = useState("");
    const [process, setProcess] = useState(false);

    // handle request
    const submitRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let data = { loanId, loanType, phone, client, oldTransID, oldDate, oldAmount, newTransID, newDate, newAmount, comment }

        let url = `/loans/staff/reversals`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setProcess(false);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        if(res?.message){
            dispatch(show({
                type : "success",
                state : true,
                message : res?.message
            }))
            setTimeout(() => {
                setOldTransID("");
                setOldDate("");
                setOldAmount("");
                setNewTransID("");
                setNewDate("");
                setNewAmount("");
                setComment("");
                closeModal();
            }, 500)
        }
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900 mb-2">
                                    Submit a transaction reversal request
                                </Dialog.Title>

                                <form className='grid grid-cols-1'>
                                    <div className="col-span-full space-y-6">
                                        <div className="relative w-[95%] mx-auto flex py-1.5 items-center">
                                            <div className="flex-grow border-t border-slate-200"></div>
                                            <span className="flex-shrink mx-4 text-sm text-gray-400">Current</span>
                                            <div className="flex-grow border-t border-slate-200"></div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                            <FormInput 
                                                label={"Transaction ID"}
                                                type={"text"}
                                                name={"oldTransID"}
                                                id={"oldTransID"}
                                                value={oldTransID}
                                                setValue={setOldTransID}
                                            />

                                            <div>
                                                <label htmlFor="oldDate" className='form-label'>Transaction date:</label>
                                                <input type="date" name="oldDate" id="oldDate" className='form-input' autoComplete="oldDate" max={new Date().toISOString().split('T')[0]} required value={oldDate} onChange={(e) => setOldDate(e.target.value)} />
                                            </div>

                                            <FormInput 
                                                label={"Amount"}
                                                type={"number"}
                                                name={"oldAmount"}
                                                id={"oldAmount"}
                                                value={oldAmount}
                                                setValue={setOldAmount}
                                            />
                                        </div>

                                        <div className="relative w-[95%] mx-auto flex py-1.5 items-center">
                                            <div className="flex-grow border-t border-slate-200"></div>
                                            <span className="flex-shrink mx-4 text-sm text-gray-400">Updated</span>
                                            <div className="flex-grow border-t border-slate-200"></div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                            <FormInput 
                                                label={"Transaction ID"}
                                                type={"text"}
                                                name={"newTransID"}
                                                id={"newTransID"}
                                                value={newTransID}
                                                setValue={setNewTransID}
                                            />

                                            <div>
                                                <label htmlFor="newDate" className='form-label'>Transaction date:</label>
                                                <input type="date" name="newDate" id="newDate" className='form-input' autoComplete="newDate" max={new Date().toISOString().split('T')[0]} required value={newDate} onChange={(e) => setNewDate(e.target.value)} />
                                            </div>

                                            <FormInput 
                                                label={"Amount"}
                                                type={"number"}
                                                name={"newAmount"}
                                                id={"newAmount"}
                                                value={newAmount}
                                                setValue={setNewAmount}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor={"comment"} className='form-label'>
                                                Comments
                                            </label>

                                            <textarea 
                                                className='form-textarea disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-400 disabled:cursor-not-allowed' 
                                                id={"comment"} 
                                                name={"comment"} 
                                                cols="30" 
                                                rows={4} 
                                                placeholder={"state reason for reversal"}
                                                value={comment}
                                                onChange={e => setComment(e.target.value)}
                                            ></textarea>
                                        </div>

                                        <div>
                                            <Button 
                                                btnText={"Submit"}
                                                btnClasses={"form-button-default"}
                                                btnType={"submit"}
                                                btnFunction={submitRequest}
                                                processing={process}
                                            />
                                        </div>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>

            </Dialog>
        </Transition>
    )
}

export default Reversal