import React from 'react'

function FormInput({ label, type, name, id, placeholder, disabled, value, setValue }) {
    return (
        <div>
            <label htmlFor={name} className='form-label'>{label}</label>
            <input 
                type={type}
                name={name}
                id={id}
                className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </div>
    )
}

export default FormInput