import React from 'react';
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectStaff } from "../slices/staffSlice";

function ProtectedRoute() {
    const staff = useSelector(selectStaff);
    
    return (
        staff !== null ? <Outlet /> : <Navigate to="/login" />
    )
}

export default ProtectedRoute