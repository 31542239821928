import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { selectRefreshToken, reauthorize } from "../../slices/staffSlice";
import { adminPortalRequest } from "../../Requests/getRequests";
import { Button } from "../../components";
import { hide } from "../../slices/toastSlice";
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

function Toast() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const refreshToken = useSelector(selectRefreshToken);
    const [processing, setProcessing] = useState(false);

    const state = useSelector((state) => state.toast.state);
    const message = useSelector((state) => state.toast.message);
    const type = useSelector((state) => state.toast.type);

    const refreshRequest = async() => {
        setProcessing(true);
        let url = `/auth/staff/refresh_token`;
        let headers = {
            "Content-Type" : "application/json",
            "refreshtoken" : `${refreshToken}`
        }

        let res = await adminPortalRequest(url, "GET", headers, {}, {});

        // do this if server times out
        if(res?.status === 501){
            navigate("/login");
        }

        if(res?.accessToken){
            dispatch(reauthorize(res));
            setProcessing(false);
            setTimeout(() => {
                dispatch(hide())
            },500)
        } else {
            navigate("/login");
        }
    }

    useEffect(() => {
        if(state){
            if(message === "Your session has expired"){
                return;
            } else {
                const intervalId = setInterval(() => {
                    dispatch(hide())
                }, 3000)
    
                return () => clearInterval(intervalId);
            }
        }
    },[state, message, dispatch])

    return (
        <div className={`fixed duration-150 inset-x-0 z-50 ml-auto flex ${message !== "Your session has expired" ? 'flex-row' : 'flex-col'} items-center justify-center rounded-md px-8 py-3 max-w-fit w-full animate-[fade-in] ease-linear shadow-sm border top-6 transition delay-75 
        ${state ? 'mr-8' : '-mr-96'} 
        ${type === "success" ? "bg-emerald-500 text-white border-emerald-500" : type === "error" ? "bg-red-500 text-white  border-red-500" : ""}`}>
            <span>
                {type === "success" ? 
                <CheckCircleIcon 
                    className="h-6 w-6 text-white inline-block mr-2" 
                    aria-hidden="true"
                /> : 
                type === "error" ? 
                <XCircleIcon 
                    className="h-6 w-6 text-white inline-block mr-2" 
                    aria-hidden="true"
                /> : ""}
            </span> 
            {message}

            {message === "Your session has expired" && 
            <Button 
                btnClasses={"alert-button mt-5 mb-2"}
                btnText="Refresh"
                btnFunction={refreshRequest}
                processing={processing}
            />
            }
        </div>
    )
}

export default Toast