import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { Topbar, Sidebar, Heading, Loader, Member, Pagination, Search, Button } from "../../../components";
import { adminPortalRequest } from "../../../Requests/getRequests";
import { authStaff } from '../../../utils/constants';
import { show } from '../../../slices/toastSlice';
import AddStaff from './AddStaff';
import { useNavigate } from 'react-router-dom';

function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [staffList, setStaffList] = useState();
    const [defaultData, setDefaultData] = useState();

    // for add new staff account modal
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    const closeModal = () => {
        setIsOpen(!isOpen);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = staffList?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(staffList?.length / recordsPerPage);

    useEffect(() => {
        let unsubscribed = false;
        const getCounts = async(accessToken) => {
            let staffUrl = `/auth/staff/list`;
            let headers = authStaff(accessToken)

            let res = await adminPortalRequest(staffUrl, "GET", headers, {}, {});
            if(!unsubscribed){
                if(res?.code === 401){
                    navigate("/login");
                    dispatch(show({
                        type : "error",
                        state : true,
                        message : "Your session has expired. Please login to continue."
                    }))
                    return
                } else {
                    setStaffList(res);
                    setDefaultData(res);
                }
            }
        }

        getCounts(accessToken);

        return () => {
            unsubscribed = true
        }

    },[accessToken, dispatch, navigate])

    return (
        <>
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className="section-container">
                    <div className='flex items-center justify-between mb-3'>
                        <div className='inline-flex items-end space-x-3'>
                            <Heading 
                                text={`Staff`}
                                size="text-3xl font-bold tracking-tight text-gray-700"
                            />

                            {staffList && <span className='text-sm text-gray-500 mb-2'>{staffList?.length} members</span>}
                        </div>

                        <div className='inline-flex items-center justify-center space-x-3'>
                            <Button 
                                btnClasses="default-button"
                                btnText={"Add Staff"}
                                btnFunction={openModal}
                            />

                            <Search 
                                data={staffList}
                                setData={setStaffList}
                                placeholder="Search staff"
                                defaultData={defaultData}
                            />
                        </div>
                    </div>

                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50'>
                                    <tr>
                                        <th scope="col" className='px-4 py-3'>Name</th>
                                        <th scope="col" className='px-4 py-3'>Email Address</th>
                                        <th scope="col" className='px-4 py-3'>Phone Number</th>
                                        <th scope="col" className='px-4 py-3'>Status</th>
                                        <th scope="col" className='px-4 py-3'>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {currentRecords ? (
                                        currentRecords && currentRecords?.length > 0 ? (
                                            currentRecords?.map((item, index) => (
                                                <Member 
                                                    key={index}
                                                    item={item}
                                                />
                                            ))
                                        ) : (
                                            <tr className="px-4 py-6 border-b hover:bg-gray-100">
                                                <td colSpan="5" className='px-4 py-3 text-center font-medium'>
                                                    No staff members
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        <tr className="px-4 py-6 border-b hover:bg-gray-100">
                                            <td colSpan="5" className='px-4 py-3 text-center'>
                                                <Loader 
                                                    color="text-gray-500"
                                                    size="w-5 h-5"
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            {currentRecords && 
                            <Pagination 
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                length={staffList?.length}
                            />
                            }
                        </div>
                    </div>
                </section>
            </main>
        
        </div>

        <AddStaff 
            isOpen={isOpen}
            closeModal={closeModal}
        />
        </>
    )
}

export default Index