import { useState } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { fileSize } from '../../utils/constants';

function FormUpload({ label, name, id, setValue, required }) {

    const [fileName, setFileName] = useState('(No file selected)');
    const [size, setSize] = useState(0);

    const handleFileChange = (e) => {
        setFileName(e.target.files[0].name);
        setValue(e.target.files[0]);
        setSize(e.target.files[0].size);
    }
    
    return (
        <div>
            <label htmlFor={name} className="form-label">
                {label}
            </label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 overflow-hidden">
                <div className="text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                    <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                        <label htmlFor={name} className="relative cursor-pointer rounded-md font-semibold text-gold-700 hover:text-gold-500">
                            <span>Upload a file</span>
                            <input
                                id={id}
                                name={name}
                                type="file"
                                className="sr-only"
                                onChange={handleFileChange}
                                accept='image/*, .doc, .docx, .pdf'
                                required={required}
                            />
                        </label>
                        {/* <p className="pl-1 text-slate-700 dark:text-text-dark">or drag and drop</p> */}
                    </div>
                    <p className="text-xs leading-5 text-slate-700">
                        PNG, JPG, JPEG, DOC up to 10MB
                    </p>

                    <p className="min-w-[50%] max-w-auto mx-auto mt-3 -mb-6 text-xs leading-5 text-slate-700 truncate">
                        {fileName}
                    </p>

                    <p className="min-w-[50%] max-w-auto mx-auto mt-6 -mb-6 text-xs leading-5 text-slate-700 truncate">
                        {fileSize(size)}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FormUpload