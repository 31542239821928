import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { dateToHumanFormat } from '../../utils/constants'

function ClientTable({ client }) {

    const employmentNatureOptions = [
        {
            value : "fullTime",
            text : "Full Time"
        },
        {
            value : "casual",
            text : "Casual"
        }
    ]

    return (
        <Fragment>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Name</td>
                <td className='px-4 py-3'>{client?.otherNames} {client?.surname}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Gender</td>
                <td className='px-4 py-3 capitalize'>{client?.gender}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Phone Number</td>
                <td className='px-4 py-3'>{client?.phone}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Phone Network</td>
                <td className='px-4 py-3 uppercase'>{client?.network}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Address</td>
                <td className='px-4 py-3'>{client?.address}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Date of Birth</td>
                <td className='px-4 py-3'>{dateToHumanFormat(client?.dob)}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>ID Type - ID Number</td>
                <td className='px-4 py-3'>{client?.idType} - <span className='font-medium'>({client?.idNumber})</span></td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Education</td>
                <td className='px-4 py-3'>{client?.education}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Occupation</td>
                <td className='px-4 py-3 capitalize'>{client?.occupation}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Employment Nature</td>
                <td className='px-4 py-3'>{employmentNatureOptions.find(option => option.value === client?.employNature)?.text}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Digital Address</td>
                <td className='px-4 py-3'>{client?.digitalAddress}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Nearest Landmark</td>
                <td className='px-4 py-3'>{client?.nearestLandmark}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Next of Kin</td>
                <td className='px-4 py-3'>{client?.nextOfKin}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Next of Kin Phone Number</td>
                <td className='px-4 py-3'>{client?.nextOfKinPhone}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Address of Next of Kin</td>
                <td className='px-4 py-3'>{client?.nextOfKinAddress}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Relationship to Next of Kin</td>
                <td className='px-4 py-3'>{client?.nextOfKinRelationship}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Account created by</td>
                <td className='px-4 py-3 capitalize'>{client?.tag}</td>
            </tr>
            <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-medium'>Loans ({client?.loans.length})</td>
                <td className='px-4 py-3 grid grid-cols-5 gap-4'>
                    {client?.loans?.length > 0 ? client?.loans?.map((loan, index) => (
                        <Link 
                            key={index} 
                            className={`px-2.5 py-2 rounded-md text-xs ${loan.state === 'active' ? 'bg-emerald-100 text-emerald-700' : loan.state === 'completed' ? 'bg-sky-100 text-sky-700' : loan.state === 'rejected' ? 'bg-red-100 text-red-700' : 'bg-slate-100 text-slate-700'}`}
                            to={
                                loan.state === 'initial' ? 
                                `/${loan?.type.toLowerCase()}/${loan._id.split("-").pop()}` : 
                                `/${loan.state}/${loan._id.split("-").pop()}`
                            }
                        >
                            {loan?.type} - {loan?.amount} - <span className='capitalize'>{loan?.state}</span>
                        </Link>
                    )) : (
                        <span className='text-xs'>No loans</span>
                    )}
                </td>
            </tr>
        </Fragment>
    )
}

export default ClientTable