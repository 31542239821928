import { useState } from "react";
import { AutoDisburse, ManualDisburse } from "../../../components";
import { Tab } from '@headlessui/react';
import { loanTypesOld } from "../../../utils/constants";
import PreApproval from "./PreApproval";

function Disburse({ loan }) {

    const [confirmedClient, setConfirmedClient] = useState(false);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    if(loan?.type === loanTypesOld.cediscredit && !confirmedClient){
        return (
            <PreApproval 
                loan={loan} 
                setConfirmedClient={setConfirmedClient}
            />
        )
    }

    return (
        <>
        <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl p-1">
                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                    Manual Disbursement
                </Tab>
                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                    Auto Disbursement (MTN)
                </Tab>
            </Tab.List>

            <Tab.Panels className="-mt-2">
                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                    <ManualDisburse loan={loan} />
                </Tab.Panel>
                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                    <AutoDisburse loan={loan} />  
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>

        </>
    )
}

export default Disburse