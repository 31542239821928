import { useState } from 'react';
import { Button } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { postRequest } from "../../Requests/postRequests";
import { login } from "../../slices/staffSlice";
import { show } from '../../slices/toastSlice';
import Logo from "../../assets/images/logo.png";

function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [success, setSuccess] = useState();
    const [process, setProcess] = useState();

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let data = {
            email,
            password
        }

        let url = `/auth/staff/login`;
        let res = await postRequest(url, data);
        setProcess(false);
        
        if(res && res.accessToken){
            setSuccess(true);
            dispatch(login(res));
            navigate("/")
        } else {
            setProcess(false);
            dispatch(show({
                type : "error",
                state : true,
                message : `${res.err}`
            }))
        }
    }

    return (
        <>
        <div className='min-h-[100vh] min-w-[100vw] bg-gray-50 flex flex-col items-center justify-center relative'>
            <img src={Logo} alt='Company Logo' className='w-auto h-12 object-cover mx-auto mb-6' />

            <div className='w-[90%] md:w-[60%] lg:w-[30%] shadow-sm rounded-lg h-auto p-6 space-y-6 bg-white'>
                <h4 className='text-display font-medium text-3xl text-center'>
                    Login
                </h4>

                <form id="admin-login" onSubmit={handleRequest} className='grid grid-cols-1 gap-6 px-2'>
                    <div>
                        <label htmlFor="email" className='form-label'>Email Address</label>
                        <input type="email" name="email" id="email" className='form-input' autoComplete="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div>
                        <label htmlFor="password" className='form-label'>Password</label>
                        <input type="password" name="password" id="password" className='form-input' autoComplete="password" value={password} required onChange={(e) => setPassword(e.target.value)} />
                    </div>

                    <div>
                        <Button 
                            btnText={success ? <CheckCircleIcon className="w-5 h-5" /> : "Sign In"}
                            btnClasses={"form-button"}
                            btnType="submit"
                            processing={process}
                        />
                    </div>
                </form>

                <p className='text-center'>
                    <Link to="/forgot-password" className='hover:underline'>Forgot password</Link>
                </p>

                <p className='text-center'>
                    <span className='text-dark-blue'>New Admin? </span> 
                    <Link to="/register" className='hover:underline'>
                        Sign up
                    </Link>
                </p>
            </div>
        </div>
        </>
    )
}

export default Login