import { useState } from 'react'
import { Switch } from '@headlessui/react'

function Toggle({ label, value, setValue }) {

    const [enabled, setEnabled] = useState(value);

    const handleToggle = () => {
        setEnabled(!enabled);
        setValue(!enabled);
    }

    return (
        <div className='isolate flex items-center justify-between text-sm'>
            <span className='form-label'>{label}</span>
            <Switch
                name={label}
                checked={enabled}
                onChange={handleToggle}
                className={`${enabled ? 'bg-gold-700' : 'bg-gray-200'} relative inline-flex h-[28px] w-[52px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}>
                <span className="sr-only">{label}</span>
                <span
                aria-hidden="true"
                className={`${enabled ? 'translate-x-6' : 'translate-x-0'}
                    pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
        </div>
    )
}

export default Toggle