import React from 'react';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

function Loans() {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Number of loan applications',
            },
        },
    };

    const data = {
        labels: ['LoanMe', 'CapitalMe', 'CedisCredit', 'CashMe', 'Pension', 'Employer', 'Investment'],
        datasets: [
            {
            label: '# of Loans',
            data: [8, 3, 5, 2, 4, 10, 12],
            backgroundColor: [
                'rgba(255, 99, 132, 0.9)',
                'rgba(54, 162, 235, 0.9)',
                'rgba(255, 206, 86, 0.9)',
                'rgba(75, 192, 192, 0.9)',
                'rgba(234, 162, 235, 0.9)',
                'rgba(55, 206, 86, 0.9)',
                'rgba(175, 192, 192, 0.9)'
            ],
            borderWidth: 1,
            },
        ],
    };

    return (
        <PolarArea options={options} data={data} />
    )
}

export default Loans