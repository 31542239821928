import axios from "axios";

// const authEndPoint = process.env.REACT_APP_STAGING_AUTH;
const gatewayEndPoint = process.env.REACT_APP_API_STAGING;

const postRequest = async(url, data) => {
    let requestOptions = {
        method : "POST",
        withCredentials : false,
        headers : {
            "Content-Type" : "application/json"
        },
        data
    }

    try {
        
        let res = await axios(gatewayEndPoint + url, requestOptions);

        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            // console.log(res.data)
            return res.data;
        }

    } catch (error) {
        if(error){
            return error.response.data
        }
    }
}

const putRequest = async(url, data) => {
    let requestOptions = {
        method : "PUT",
        withCredentials : false,
        headers : {
            "Content-Type" : "application/json"
        },
        data
    }

    try {
        
        let res = await axios(gatewayEndPoint + url, requestOptions);

        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            // console.log(res.data)
            return res.data;
        }

    } catch (error) {
        if(error){
            return error.response.data
        }
    }
}


export { 
    postRequest,
    putRequest
}