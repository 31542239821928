import { useState } from 'react';
import { Button, FormInput } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../../slices/staffSlice";
import { fetchRequests } from '../../../Requests/getRequests';
import { authStaff, dateToHumanFormat } from "../../../utils/constants";
import { useNavigate } from 'react-router-dom';
import { show } from '../../../slices/toastSlice';

function ManualDisburse({ loan }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const [transactionID, setTransactionID] = useState('');
    const [disbursementDate, setDisbursementDate] = useState('');
    const [repaymentDate, setRepaymentDate] = useState('');
    const [amountToDisburse, setAmountToDisburse] = useState(loan?.amountReq);
    const [processing, setProcessing] = useState(false);

    const handleDisbursement = async(e) => {
        e.preventDefault();
        setProcessing(true);

        let url = `/loans/staff/disburse-loan`;
        let headers = authStaff(accessToken);

        let data = {
            _id : loan?._id,
            type : loan?.type.toLowerCase(),
            state : loan?.state,
            transactionType : 'disburse',
            transactionID : transactionID.replace(/[^a-zA-Z0-9]/g, ''),
            disbursementDate,
            repaymentDate,
            amount : amountToDisburse,
        }

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setProcessing(false);
        
        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : `Disbursement submitted successfully.`
        }))

        setTimeout(() => {
            navigate("/active-loans")
        },1500)
    }

    return (
        <div className='rounded-md'>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table className='min-w-full'>
                        <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                            <tr>
                                <th scope="col" className='px-4 py-3'>
                                    Manual Disbursement
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr className="px-4 py-6 border-b text-gray-500">
                                <td className='px-4 py-3'>
                                    <form onSubmit={handleDisbursement}>
                                        <div className='grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-6'>
                                            <div>
                                                <label htmlFor="transactionID" className='form-label'>Transaction ID</label>
                                                <FormInput 
                                                    type="text"
                                                    name="transactionID"
                                                    id="transactionID"
                                                    required
                                                    disabled={false}
                                                    value={transactionID}
                                                    setValue={setTransactionID}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="disbursementDate" className='form-label'>Disbursement Date</label>
                                                <input 
                                                    type="date"
                                                    name={"disbursementDate"}
                                                    id={"disbursementDate"}
                                                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                                                    required
                                                    value={disbursementDate}
                                                    onChange={e => setDisbursementDate(e.target.value)}
                                                    min={new Date(year, month - 1, 1).toISOString().split('T')[0]}
                                                    max={new Date().toISOString().split('T')[0]}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="repaymentDate" className='form-label'>Repayment Start Date</label>
                                                <input 
                                                    type="date"
                                                    name={"repaymentDate"}
                                                    id={"repaymentDate"}
                                                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                                                    required={false}
                                                    value={repaymentDate}
                                                    onChange={e => setRepaymentDate(e.target.value)}
                                                    min={new Date().toISOString().split('T')[0]}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="amountToDisburse" className='form-label'>Amount to Disburse</label>
                                                <FormInput 
                                                    type="number"
                                                    name="amountToDisburse"
                                                    id="amountToDisburse"
                                                    required
                                                    value={amountToDisburse}
                                                    setValue={setAmountToDisburse}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="customerName" className='form-label'>Name of Customer</label>
                                                <FormInput 
                                                    type="text"
                                                    name="customerName"
                                                    id="customerName"
                                                    required
                                                    disabled={true}
                                                    value={`${loan?.client?.otherNames} ${loan?.client?.surname}`}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="mobileNumber" className='form-label'>Mobile Number</label>
                                                <FormInput 
                                                    type="number"
                                                    name="mobileNumber"
                                                    id="mobileNumber"
                                                    required
                                                    disabled={true}
                                                    value={loan?.phone}
                                                />
                                            </div>
                                            
                                        </div>

                                        <div className='mt-5 bg-yellow-200 p-4 rounded-md text-yellow-700'>
                                            If the loan is disbursed today without "Repayment Start Date" being altered, the repayment start date would be <strong>{dateToHumanFormat(loan?.startDate)}</strong> and would end <strong>{dateToHumanFormat(loan?.endDate)}</strong>. If the client has a "Standing Order" and this was indicated in the loan application, input the date the standing order would be activated as the "Repayment Start Date". The addictinal five (5) days would be added to the repayment end date.
                                        </div>

                                        <div className={`inline-flex items-center space-x-4 mt-6`}>
                                            <Button 
                                                btnText={"Disburse"}
                                                btnType="submit"
                                                btnClasses={"form-button w-auto"}
                                                disabled={processing}
                                                processing={processing}
                                            />
                                        </div>
                                    </form>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ManualDisburse