import React from 'react'
import { Topbar, Sidebar, PortfolioSummary, ArrearsSummary } from "..";

function Portfolio() {
    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>

                <PortfolioSummary />

                <ArrearsSummary />
                
            </main>
        </div>
    )
}

export default Portfolio