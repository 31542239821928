import React from 'react'
import { Link } from 'react-router-dom'

function LoanSearch({ loan }) {
    return (
        <Link to={`/${loan?.state}/${loan?._id}`} className='px-4 py-3.5 border border-gray-200 rounded-md hover:bg-gray-50 flex items-center divide-x divide-slate-200'>
            <div className='pl-0 px-6'>{`${loan?.client?.otherNames} ${loan?.client?.surname}`}</div>
            <div className='px-6'>{loan?.phone}</div>
            <div className='px-6'>{loan?.type}</div>
            {loan?.daysInDelinq >= 1 && (
                <div className='px-6'>
                    <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                        {loan?.daysInDelinq} days in arrears
                    </span>
                </div>
            )}
        </Link>
    )
}

export default LoanSearch