import { useState, useEffect } from 'react';
import { Topbar, Sidebar, LoansTable, Loader, Filter } from "../../components";

function Index() {

    const [loans, setLoans] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = `Loan Applications - ${process.env.REACT_APP_SITE_NAME}`;
    },[])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>

                <section className='section-container'>
                    
                    <div className='flex items-center justify-between mb-10'>
                        <h3 className='text-lg lg:text-3xl font-medium leading-6 text-display inline-flex items-center'>
                            Loan Applications {loading && <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />}
                        </h3>

                        <Filter 
                            endpoint="fetchLoans"
                            state="initial"
                            setLoading={setLoading}
                            setLoans={setLoans}
                        />
                    </div>

                    <LoansTable loans={loans} />

                </section>

            </main>

        </div>
    )
}

export default Index