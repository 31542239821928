import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { Button, FormInput } from "../../../components";
import { authStaff } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { fetchRequests } from '../../../Requests/getRequests';
import { show } from '../../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function AddStaff({ isOpen, closeModal }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [first_name, setFirst_Name] = useState("");
    const [last_name, setLast_Name] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [process, setProcess] = useState(false);

    const createRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let createUrl = `/auth/staff/addStaff`;
        let headers = authStaff(accessToken);
        let data = {
            surname : last_name,
            otherNames : first_name,
            phone : phone,
            email : email,
        }

        let res = await fetchRequests(createUrl, "POST", headers, {}, data);
        setProcess(false);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : `Your session has expired. Please login to continue.`
            }))
            return;
        }
        
        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : `New staff added successfully`
        }))
        
        setTimeout(() => {
            closeModal();
        },1500)
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900 mb-6">
                                    Add New Staff Account
                                </Dialog.Title>

                                <form className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                                    <FormInput 
                                        label="First Name"
                                        name="first_name"
                                        id="first_name"
                                        type={"text"}
                                        required
                                        value={first_name}
                                        setValue={setFirst_Name}
                                    />

                                    <FormInput 
                                        label="Last Name"
                                        name="last_name"
                                        id="last_name"
                                        type={"text"}
                                        required
                                        value={last_name}
                                        setValue={setLast_Name}
                                    />

                                    <FormInput 
                                        label="Email Address"
                                        name="email"
                                        id="email"
                                        type={"email"}
                                        required
                                        value={email}
                                        setValue={setEmail}
                                    />

                                    <FormInput 
                                        label="Phone Number"
                                        name="phone"
                                        id="phone"
                                        type={"number"}
                                        required
                                        value={phone}
                                        setValue={setPhone}
                                    />

                                    <div className="inline-flex items-center justify-between text-right space-x-4">
                                        <Button 
                                            btnText={"Cancel"}
                                            btnClasses={"w-full cancel-button"}
                                            btnFunction={closeModal}
                                        />

                                        <Button 
                                            btnType={"submit"}
                                            btnClasses={"w-full default-button"}
                                            btnText="Add Staff"
                                            btnFunction={createRequest}
                                            processing={process}
                                        />
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>

            </Dialog>
        </Transition>
    )
}

export default AddStaff