import { dateToHumanFormat } from '../../../utils/constants'

function ApprovalHistory({ item }) {
    return (
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3 font-medium capitalize w-[20%]'>
                {item?.admin}
            </td>
            <td className='px-4 py-3 font-medium capitalize w-[10%]'>
                {`${item?.decision}`}
            </td>
            <td className='px-4 py-3 font-medium capitalize w-[55%]'>
                {item?.comments}
            </td>
            <td className='px-4 py-3 font-medium capitalize w-[15%]'>
                {dateToHumanFormat(item?.date)}
            </td>
        </tr>
    )
}

export default ApprovalHistory