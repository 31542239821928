import { useDispatch, useSelector } from 'react-redux';
import { setAdminRole, selectAdminRole } from '../../slices/staffSlice';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { show } from '../../slices/toastSlice';

function RoleSwitch({ id, roles, isToggled }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedRole = useSelector(selectAdminRole);

    const switchRole = (e) => {
        dispatch(setAdminRole(e.target.value));
        dispatch(show({
            type : "success",
            state : true,
            message : `Role Switched to ${e.target.value === "lo" ? "Loan Officer" : e.target.value === "pm" ? "Portfolio Manager" : e.target.value === "hoc" ? "Head of Credit" : e.target.value === "crm" ? "Credit Risk Manager" : e.target.value === "hor" ? "Head of Risk" : "Loan Officer"}`
        }))
        setTimeout(() => {
            navigate("/")
        },300);
    }

    return (
        <>
        {isToggled ? 
            <div className='flex items-center justify-center bg-light-blue/[.5] py-2 px-2 transition-all duration-150 ease-in-out rounded-md my-2'>
                <ArrowsRightLeftIcon className='w-5 h-5' />
            </div>  
        : 
            <p className='text-xs my-2 font-medium'>Switch Roles:</p>
        }

        <select id={id} name={id} className="block w-full rounded-md py-2.5 px-1.5 text-white shadow-sm sm:max-w-xs sm:text-sm sm:leading-6 bg-light-blue/[.5] text-xs font-medium" defaultValue={selectedRole} onChange={switchRole}>
            {roles.map((role, index) => (
                <option key={index} value={role}>
                    {role === "pm" ? "Portfolio Manager" : role === "hoc" ? "Head of Credit" : role === "crm" ? "Credit Risk Manager" : role === "hor" ? "Head of Risk" : "Loan Officer"}
                </option>
            ))}
        </select>
        </>
    )
}

export default RoleSwitch