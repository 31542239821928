import { useEffect, useState } from "react";
import { Topbar, Sidebar, Loader, BreadCrump } from "../../components";
import { fetchRequests } from "../../Requests/getRequests";
import { useDispatch } from "react-redux";
import { show } from '../../slices/toastSlice';
import { authStaff } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../../slices/staffSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ClientTable from "./ClientTable";

function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const clientId = location.pathname.split("/")[2];
    const [client, setClient] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getUserDetails = async(clientId) => {
            let clientUrl = `/auth/staff/client/find`;
            let headers = authStaff(accessToken);
            let params = {
                _id : clientId
            }

            let res = await fetchRequests(clientUrl, "GET", headers, params, {});
            setIsLoading(false);

            if(res?.code === 401){
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }
            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : `${res?.err}`
                }))
                return;
            } 

            setClient(res);
        }

        getUserDetails(clientId);
    },[accessToken, clientId, dispatch, navigate])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content pb-36'>
                <section className='section-container space-y-6'> 

                    {client ? 
                        <>
                        <BreadCrump 
                            path='/'
                            main="Dashboard"
                            sub={isLoading ? 
                                <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} /> : 
                                `${client?.otherNames} ${client?.surname}'s Details - ${client?.isVerified ? 'Verified' : 'Not Verified'}`
                            }
                        />

                        <div className='rounded-md'>
                            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                    <table className='min-w-full'>
                                        <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                            <tr>
                                                <th scope="col" className='px-4 py-3 w-[40%]'>Detail</th>
                                                <th scope="col" className='px-4 py-3 w-[60%]'>Entry</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <ClientTable 
                                                client={client}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        </>
                    : 
                    <div className='w-full py-2 inline-flex justify-center items-center'>
                        <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />
                    </div>
                    }

                </section>
            </main>
        </div>
    )
}

export default Index