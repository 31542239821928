import React from 'react'

function Search({ data, setData, placeholder, defaultData }) {

    return (
        <div>
            <input 
                type="text" 
                placeholder={placeholder} 
                className="border border-gray-300 rounded-md p-2 w-80"
                onChange={(e) => {
                    const search = e.target.value;
                    const filtered = data.filter((item) => {
                        return Object.keys(item).some((key) => {
                            return item[key].toString().toLowerCase().includes(search.toLowerCase());
                        })
                    })
                    setData(search === "" ? defaultData : filtered);
                }}
            />
        </div>
    )
}

export default Search