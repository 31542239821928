import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { Button, Toggle } from "../../../components";
import { authStaff } from "../../../utils/constants";
import { fetchRequests } from "../../../Requests/getRequests";
import { show } from '../../../slices/toastSlice';

function Deactivate({ _id, approved }) {

    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(approved);
    const [process, setProcess] = useState();

    const deactivateRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let url = `/auth/staff/disable`;
        let headers = authStaff(accessToken);
        let data = {
            _id : _id,
            status : toggle
        }

        let res = await fetchRequests(url, "PUT", headers, {}, data);
        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        } else {
            dispatch(show({
                type : "success",
                state : true,
                message : `${res?.message}`
            }))
            setTimeout(() => {
                navigate('/admin/staff');
            }, 2000)
        }

        setProcess(false);
    }

    return (
        <div className='space-y-6'>
            <span className='text-gray-500'>
                Doing this will {approved ? "disable" : "enable"} this account.
            </span>

            <form className='w-auto md:w-96'>
                <Toggle 
                    value={toggle}
                    setValue={setToggle}
                />

                <div className='inline-flex item-center space-x-3 mt-5'>
                    <Button 
                        btnText={"Submit"}
                        btnClasses="danger-button"
                        btnType={"button"}
                        btnFunction={deactivateRequest}
                        processing={process}
                    />

                    <Link to="/admin/staff" className='cancel-button'>
                        Cancel
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default Deactivate