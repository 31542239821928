import React from 'react'

function FormTextArea({ name, id, placeholder, disabled, value, setValue }) {
    return (
        <textarea 
            className='form-textarea disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-400 disabled:cursor-not-allowed' 
            id={id} 
            name={name} 
            cols="30" 
            rows={4} 
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            // onChange={e => setValue(e.target.value)}
        ></textarea>
    )
}

export default FormTextArea