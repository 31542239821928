import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccessToken } from '../slices/staffSlice';
import { fetchRequests } from '../Requests/getRequests';
import { show } from '../slices/toastSlice';
import { authStaff } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

function useLoan({ loanId, loanType, state, approved, active, completed, rejected }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const [isLoading, setIsLoading] = useState(true);
    const [loan, setLoan] = useState({});

    useEffect(() => {
        let unsubscribed = false;

        const getLoan = async() => {
            setIsLoading(true);

            let headers = authStaff(accessToken);

            let url = active ? `/loans/staff/active-loan` : 
                completed ? `/loans/staff/completed-loan` :
                rejected ? `/loans/staff/rejected-loan` :
                approved ? `/loans/staff/approvedLoan` : 
                `/loans/staff/fetchLoan`;

            let params = active || completed || rejected ? {
                _id : loanId,
                state : state
            } : {
                _id : loanId,
                type : loanType
            }

            let res = await fetchRequests(url, "GET", headers, params, {});
            setIsLoading(false);

            if(res?.code === 401){
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }

            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : res?.err
                }))
                return;
            }

            if(!unsubscribed){
                setLoan(res);
            }
        }

        getLoan();

        return () => {
            unsubscribed = true
        }
    }, [dispatch, navigate, accessToken, setIsLoading, setLoan, loanId, loanType, state, approved, active, completed, rejected])

    return { isLoading, loan }
}

export default useLoan