import React from 'react'
import { Heading } from "../../components"

function ArrearsSummary() {
    return (
        <section className='section-container space-y-6'>
            <Heading 
                size="text-3xl font-medium"
                color="text-gray-700"
                text="Arrears Report Summary - Mon Dec 05 2022"
            />

            {/* table 1 */}
            <div className='rounded-md'>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className='min-w-full'>
                            <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                <tr>
                                    <th scope="col" className='px-4 py-3'>#</th>
                                    <th scope="col" colSpan={2} className='px-4 py-3'>Portfolio Aging Analysis &amp; Loan Loss Provisioning</th>
                                    <th scope="col" className='px-4 py-3'>Number</th>
                                    <th scope="col" className='px-4 py-3'>Value (GHC)</th>
                                    <th scope="col" className='px-4 py-3'>Min Prov Rate (%)</th>
                                    <th scope="col" className='px-4 py-3'>Actual Prov Rate (GHC)</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.5
                                    </td>
                                    <td className="px-4 py-3 border">
                                        CURRENT
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Principal outstanding of all loans with at least one installments due (Principal and interest) fully paid but excluding rescheduled loans.
                                    </td>
                                    <td className="px-4 py-3 border">
                                        140
                                    </td>
                                    <td className="px-4 py-3 border">
                                        509452.55
                                    </td>
                                    <td className="px-4 py-3 border">
                                        1%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        5094.53
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.6
                                    </td>
                                    <td className="px-4 py-3 border">
                                        1 - 30 Days
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Sum of principal outstanding of all loans with at least one installment due in arrears between 1 and 30 days but excluding rescheduled loans.
                                    </td>
                                    <td className="px-4 py-3 border">
                                        48
                                    </td>
                                    <td className="px-4 py-3 border">
                                        134488.84
                                    </td>
                                    <td className="px-4 py-3 border">
                                        5%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        6724.44
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.7
                                    </td>
                                    <td className="px-4 py-3 border">
                                        31 - 60 Days
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Sum of principal outstanding of all loans with at least one installment due in arrears between 31 and 60 days but excluding rescheduled loans.
                                    </td>
                                    <td className="px-4 py-3 border">
                                        25
                                    </td>
                                    <td className="px-4 py-3 border">
                                        67619.89
                                    </td>
                                    <td className="px-4 py-3 border">
                                        20%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        13523.98
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.8
                                    </td>
                                    <td className="px-4 py-3 border">
                                        61 - 90 Days
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Sum of principal outstanding of all loans with at least one installment due in arrears between 61 and 90 days but excluding rescheduled loans.
                                    </td>
                                    <td className="px-4 py-3 border">
                                        14
                                    </td>
                                    <td className="px-4 py-3 border">
                                        36408.52
                                    </td>
                                    <td className="px-4 py-3 border">
                                        40%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        14563.41
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.9
                                    </td>
                                    <td className="px-4 py-3 border">
                                        91 - 120 Days
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Sum of principal outstanding of all loans with at least one installment due in arrears between 91 and 120 days but excluding rescheduled loans.
                                    </td>
                                    <td className="px-4 py-3 border">
                                        12
                                    </td>
                                    <td className="px-4 py-3 border">
                                        21666.24
                                    </td>
                                    <td className="px-4 py-3 border">
                                        60%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        12999.74
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.10
                                    </td>
                                    <td className="px-4 py-3 border">
                                        121 - 150 Days
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Sum of principal outstanding of all loans with at least one installment due in arrears between 121 and 150 days but excluding rescheduled loans.
                                    </td>
                                    <td className="px-4 py-3 border">
                                        11
                                    </td>
                                    <td className="px-4 py-3 border">
                                        17632.93
                                    </td>
                                    <td className="px-4 py-3 border">
                                        80%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        14106.34
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.11
                                    </td>
                                    <td className="px-4 py-3 border">
                                        151 - 180 Days
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Sum of principal outstanding of all loans with at least one installment due in arrears between 151 and 180 days but excluding rescheduled loans.
                                    </td>
                                    <td className="px-4 py-3 border">
                                        23
                                    </td>
                                    <td className="px-4 py-3 border">
                                        59964.35
                                    </td>
                                    <td className="px-4 py-3 border">
                                        100%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        59964.35
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.12
                                    </td>
                                    <td className="px-4 py-3 border">
                                        181 - 365 Days
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Sum of principal outstanding of all loans with at least one installment due in arrears between 181 and 365 days but excluding rescheduled loans.
                                    </td>
                                    <td className="px-4 py-3 border">
                                        131
                                    </td>
                                    <td className="px-4 py-3 border">
                                        285518.06
                                    </td>
                                    <td className="px-4 py-3 border">
                                        100%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        285518.06
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.13
                                    </td>
                                    <td className="px-4 py-3 border">
                                        &gt; 365 Days
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Sum of principal outstanding of all loans with at least one installment due in arrears more than days but excluding rescheduled loans.
                                    </td>
                                    <td className="px-4 py-3 border">
                                        59
                                    </td>
                                    <td className="px-4 py-3 border">
                                        107061.54
                                    </td>
                                    <td className="px-4 py-3 border">
                                        100%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        107061.54
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.14
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Rescheduled (no current arrears)
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Principal outstanding of all loans with at least one installment in arrears that have been rescheduled or restructured
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                    <td className="px-4 py-3 border">
                                        100%
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b">
                                    <td className="px-4 py-3 border">
                                        3.15
                                    </td>
                                    <td className="px-4 py-3 border">
                                        Total Gross Portfolio
                                    </td>
                                    <td className="px-4 py-3 border">
                                        
                                    </td>
                                    <td className="px-4 py-3 border">
                                        430.00
                                    </td>
                                    <td className="px-4 py-3 border">
                                        1239812.92
                                    </td>
                                    <td className="px-4 py-3 border">
                                        
                                    </td>
                                    <td className="px-4 py-3 border">
                                        0.00
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* end of table 1 */}
        </section>
    )
}

export default ArrearsSummary