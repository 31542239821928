import { FileUpload, SysFiles, ClientFiles } from "../../../components";

function Index({ loan }) {

    return (
        <div className='application-content'>
            <div className='flex flex-col space-y-10'>
                {(loan?.state !== "active" && loan?.state !== "completed") && (
                    <FileUpload loan={loan} />
                )}

                {/* <StaffFiles loan={loan} /> */}

                <SysFiles loan={loan} />

                <ClientFiles name="Loan" loan={loan} />

                <ClientFiles name="Client" loan={loan} />
            </div>
        </div>
    )
}

export default Index