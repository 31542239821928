import { createSlice } from "@reduxjs/toolkit";
// import jwt_decode from "jwt-decode";

const initialState = {
    staff : null,
    // type : null,
    idToken : null,
    accessToken : null,
    refreshToken : null,
    loan : null,
    _id : null,
    adminRole : null
}

export const staffSlice = createSlice({
    name : "staff",
    initialState,
    reducers : {
        login : (state, action) => {
            state.staff = action.payload.staff;
            state.idToken = action.payload.idToken;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.adminRole = action.payload.staff.roles[0];
        },
        logout : (state) => {
            state.staff = null;
            state.idToken = null;
            state.accessToken = null;
            state.refreshToken = null;
            state.loan = null;
            state._id = null;
            state.adminRole = null;
            localStorage.removeItem("persist:root");
        },
        reauthorize : (state, action) => {
            state.idToken = action.payload.idToken;
            state.accessToken = action.payload.accessToken;
        },
        currentLoan : (state, action) => {
            state.loan = action.payload;
        },
        isolateStaff : (state, action) => {
            state._id = action.payload
        },
        setAdminRole : (state, action) => {
            state.adminRole = action.payload
        }
    }
});

export const { login, logout, reauthorize, currentLoan, isolateStaff, setAdminRole } = staffSlice.actions;

export const selectStaff = (state) => state.staff.staff;

export const selectRole = (state) => state.staff.staff.roles;

export const selectIDToken = (state) => state.staff.idToken;

export const selectAccessToken = (state) => state.staff.accessToken;

export const selectRefreshToken = (state) => state.staff.refreshToken;

export const selectLoan = (state) => state.staff.loan;

export const selectStaffId = (state) => state.staff._id;

export const selectAdminRole = (state) => state.staff.adminRole;

export default staffSlice.reducer;