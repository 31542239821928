import { useState } from 'react';
import { useSelector } from "react-redux";
import { authStaff } from "../../../utils/constants";
import { selectAccessToken } from "../../../slices/staffSlice";
import { accessFileRequest } from "../../../Requests/getRequests";
import { Loader, PhotoModal } from '../../../components';

function SysFile({ file, loanId, phone }) {

    const fileExt = file.split(".").pop();
    const fileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];

    const accessToken = useSelector(selectAccessToken);
    const [imageSrc, setImageSrc] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [loading, isLoading] = useState(false);

    function closeModal() {
        setIsOpen(false)
    }

    const getUrl = async() => {
        isLoading(true);
        let url = `/loans/staff/getFile`;
        let headers = authStaff(accessToken)
        let params = {
            loanId : loanId,
            phone : phone,
            name : file
        }

        let res = await accessFileRequest(url, headers, params);

        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        const fileName = res.headers['content-disposition'].split('filename=')[1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        const extension = fileName.split('.')[1];

        if(fileExtensions.includes(extension)){
            link.click();
            link.remove();
        } else {
            setImageSrc(fileUrl);
            setIsOpen(!isOpen);
        }

        isLoading(false);
    }

    return (
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700">
            <td className='px-4 py-3'>
                <span>{file}</span>
            </td>
            <td className='px-4 py-3 inline-flex items-center justify-end space-x-4 w-full'>
                {loading &&
                    <span>
                        <Loader color="text-gray-700" size="3" />
                    </span>
                } 
                <span className='text-light-blue cursor-pointer' onClick={getUrl}>
                    {fileExtensions.includes(fileExt) ? 'Download file' : 'View file'}
                </span>
            </td>
            <td className='hidden'>
                <PhotoModal 
                    imageUrl={imageSrc} 
                    isOpen={isOpen} 
                    closeModal={closeModal} 
                />
            </td>
        </tr>
    )
}

export default SysFile