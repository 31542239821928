import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../slices/staffSlice';
import { fetchRequests } from '../Requests/getRequests';
import { show } from '../slices/toastSlice';
import { authStaff } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

function usePayments({ state }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const [isLoading, setIsLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        let unsubscribed = false;

        const fetchPayments = async() => {
            setIsLoading(true);

            let headers = authStaff(accessToken);

            let url = `/payments/staff/get-payments`;
            
            let params = {
                state : state
            }

            let res = await fetchRequests(url, "GET", headers, params, {});
            setIsLoading(false);

            if(res?.code === 401){
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }

            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : res?.err
                }))
                return;
            }

            if(!unsubscribed){
                setTransactions(res);
            }
        }

        fetchPayments();

        return () => {
            unsubscribed = true;
        }
    }, [dispatch, navigate, accessToken, setIsLoading, setTransactions, state])

    return { isLoading, transactions }
}

export default usePayments