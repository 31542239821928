import React from 'react'

function BusinessActs({ business, loan }) {
    
    return (
        <>
        <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
            <td className='px-4 py-3'></td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3 font-bold'>Business Activities Information</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Business Activities</td>
            <td className='px-4 py-3'>{business?.businessActivities}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Sector</td>
            <td className='px-4 py-3'>{business?.businessSector}</td>
        </tr>
        {/* <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Subsector</td>
            <td className='px-4 py-3'>{business?.subSector}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Monthly Cost of Sales</td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Monthly Business Operating Sales</td>
            <td className='px-4 py-3'></td>
        </tr> */}
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Customer Types</td>
            <td className='px-4 py-3'>{business?.customerTypes[0]}</td>
        </tr>
        {/* <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Cash available in the business</td>
            <td className='px-4 py-3'>{loan?.bankOrMomoBalance}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Receivables</td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Payables</td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Other Assets</td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Inventory (GHS)</td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>How long does your inventory last before you sell</td>
            <td className='px-4 py-3'>{loan?.inventoryDuration}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>How long on average do you collect money upon sale</td>
            <td className='px-4 py-3'>{loan?.salesMoneyCollectionFrequency}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>On average, how long do your suppliers require you to pay them</td>
            <td className='px-4 py-3'>{loan?.supplierPaymentFrequency}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Means of accepting sales</td>
            <td className='px-4 py-3'>{loan?.salesMoneyCollectionFrequency}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>How often do you stock up your inventory</td>
            <td className='px-4 py-3'>{loan?.inventoryStockFrequency}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Total purchase for the month</td>
            <td className='px-4 py-3'>{loan?.monthlyBusinessIncome}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover: text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Gross profit margin</td>
            <td className='px-4 py-3'></td>
        </tr> */}
        </>
    )
}

export default BusinessActs