import { useState } from 'react';
import { Button, LoanSearch } from "../../components";
import { fetchRequests } from '../../Requests/getRequests';
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../slices/staffSlice";
import { authStaff } from '../../utils/constants';
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function SearchLoan() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [phone, setPhone] = useState("");
    const [loanState, setLoanState] = useState("");
    const [process, setProcess] = useState(false);
    const [searchedLoans, setSearchedLoans] = useState(null);

    const handleSearch = async(e) => {
        e.preventDefault();
        setProcess(true);

        if(phone === ""){
            setProcess(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Please enter a phone number"
            }))
            return;
        }
        if(loanState === ""){
            setProcess(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Please select a loan state"
            }))
            return;
        } 

        let url = `/loans/staff/search-loans`;
        let headers = authStaff(accessToken);

        let params = {
            phone,
            state : loanState
        }

        let res = await fetchRequests(url, "GET", headers, params, {});
        setProcess(false);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res.message}`
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : "Search successful"
        }))
        setSearchedLoans(res);
        
    }

    return (
        <section className='section-container'>
            <h3 className='text-3xl font-medium leading-6 text-display mb-3'>Search Loans and Guarantors</h3>

            <form className='flex space-x-4 mt-6' onSubmit={handleSearch}>
                <div className='w-1/2 lg:w-1/4'>
                    <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-700">
                        Enter Phone Number
                    </label>
                    <input type="search" name="phoneNumber" id="phoneNumber" className='form-input' placeholder='search by phone number' onChange={(e) => setPhone(e.target.value)} required />
                </div>
                <div className='w-1/2 lg:w-1/4'>
                    <label htmlFor="loan-filter" className="block text-sm font-medium leading-6 text-gray-700">
                        Select state of loan
                    </label>
                    <select name="loan-filter" id="loan-filter" className='form-input' required onChange={(e) => setLoanState(e.target.value)}>
                        <option>-- select option --</option>
                        <option value="active">Active Loans</option>
                        <option value="completed">Completed Loans</option>
                        <option value="guarantors">Guarantors (Active Loans)</option>
                    </select>
                </div>
                <div className='mt-6'>
                    <Button 
                        btnText={"Search"}
                        btntype={"button"}
                        btnClasses={"form-button mt-2"}
                        processing={process}
                    />
                </div>
            </form>

            {searchedLoans && (
                searchedLoans?.length > 0 ? (
                    <div className="block mt-6">
                        <h6 className='font-bold leading-6 text-display mb-3'>Search Results</h6>

                        <div className='w-100 flex flex-col space-y-1'>
                            {searchedLoans?.map((loan, index) => (
                                <LoanSearch 
                                    key={index}
                                    loan={loan}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="block mt-6">
                        <h6 className='font-bold leading-6 text-display mb-3'>Search Results</h6>
                        <p className='px-4 py-3.5 border border-gray-200 rounded-md flex items-center'>
                            No results found
                        </p>
                    </div>
                )
            )}
        </section>
    )
}

export default SearchLoan