import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, FormRadio } from "../../components";
import { adminRolesSelect } from "../../utils/constants";
import { postRequest } from "../../Requests/postRequests";
import { useDispatch } from "react-redux";
import { show } from "../../slices/toastSlice";
import { validateEmail } from "../../utils/constants";
import { formatName } from "../../utils/constants";
import Logo from "../../assets/images/logo.png";

function Register() {
    
    const dispatch = useDispatch();
    const [first_name, setFirst_Name] = useState();
    const [last_name, setLast_Name] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirm_password, setConfirm_Password] = useState();
    const [phone_number, setPhone_Number] = useState();
    const [roles, setRoles] = useState([]);
    const [process, setProcess] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        if(!first_name || !last_name || first_name.length < 3 || last_name.length < 3){
            dispatch(show({
                type : "error",
                state : true,
                message : "Kindly provide a valid name"
            }))
            return
        }

        if(!email || !validateEmail(email)){
            dispatch(show({
                type : "error",
                state : true,
                message : "Kindly provide a valid email"
            }))
            return
        }

        if(confirm_password !== password){
            dispatch(show({
                type : "error",
                state : true,
                message : "Passwords do not match"
            }))
            return 
        }

        if(phone_number.length !== 10){
            dispatch(show({
                type : "error",
                state : true,
                message : "Phone number must be 10 digits"
            }))
            return
        }

        if(roles.length < 1){
            dispatch(show({
                type : "error",
                state : true,
                message : "Kindly select a role"
            }))
            return
        }

        let data = {
            otherNames : formatName(first_name),
            surname : formatName(last_name),
            email,
            password,
            phone : phone_number,
            roles
        }

        let url = `/auth/staff/register`;
        let res = await postRequest(url, data);
        setProcess(false);
        
        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : `An admin will confirm your submission`
        }))

    }

    return (
        <>
        <div className='min-h-[100vh] min-w-[100vw] bg-gray-50 flex flex-col items-center justify-center py-12'>
            <img src={Logo} alt='Company Logo' className='w-auto h-12 object-cover mx-auto mb-6' />

            <div className='w-[90%] md:w-[80%] lg:w-[40%] shadow-sm rounded-lg h-auto p-6 space-y-6 bg-white'>
                <h4 className='text-display font-medium text-3xl text-center'>
                    Register
                </h4>

                <form id="admin-login" onSubmit={handleRequest} className='grid grid-cols-1 md:grid-cols-2 gap-6 px-2'>
                    <div className="col-span-1">
                        <label htmlFor="first-name" className="form-label">First Name</label>
                        <input type="text" name="first-name" id="first-name" className='form-input' autoComplete="first-name" required onChange={(e) => setFirst_Name(e.target.value)} />
                    </div>
                   
                    <div className="col-span-1">
                        <label htmlFor="last-name" className="form-label">Last Name</label>
                        <input type="text" name="last-name" id="last-name" className='form-input' autoComplete="last-name" required onChange={(e) => setLast_Name(e.target.value)} />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="email" className="form-label">Email Address</label>
                        <input type="email" name="email" id="email" className='form-input' autoComplete="email" required onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="phone-number" className="form-label">Phone Number <span className="text-gray-300 italic text-sm">(eg: 0241112345)</span></label>
                        <input type="text" name="phone-number" id="phone-number" className='form-input' autoComplete="phone-number" required onChange={(e) => setPhone_Number(e.target.value)} />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" name="password" id="password" className='form-input' autoComplete="password" required onChange={(e) => setPassword(e.target.value)} />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="confirm-password" className="form-label">Confirm Password</label>
                        <input type="password" name="confirm-password" id="confirm-password" className='form-input' autoComplete="confirm-password" required onChange={(e) => setConfirm_Password(e.target.value)} />
                    </div>

                    <FormRadio 
                        label={"Select Role"}
                        type={"checkbox"}
                        content={adminRolesSelect}
                        value={roles}
                        setValue={setRoles}
                    />

                    <div className="col-span-2">
                        <Button 
                            btnText={"Register"}
                            btnClasses={"form-button"}
                            btnType="submit"
                            processing={process}
                        />
                    </div>
                </form>

                <p className='text-center'>
                    <Link to="/login" className='hover:underline'>Login</Link>
                </p>
            </div>
        </div>
        </>
    )
}

export default Register