import { Tab } from '@headlessui/react';
import { Topbar, Sidebar, AccountIndex, ApplicationIndex, CreditIndex, FilesIndex, StatusIndex, Loader, BreadCrump } from "../../components";
import { useLocation } from "react-router-dom";
import { useLoan } from '../../hooks';

function ApprovedLoan() {

    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const loanType = path[2];
    const loanId = path[3];

    const { isLoading, loan } = useLoan(
        { loanId, loanType, state : null, approved : true, active : false, completed : false }
    );

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container space-y-6'>

                {loan ? 
                    <>
                    <BreadCrump 
                        path='/facility-letters'
                        main="Approved Loans"
                        sub={isLoading ? <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} /> : `${loan?.type} - ${loan?._id}`}
                    />

                    <div>
                        <Tab.Group>
                            <Tab.List className="flex space-x-1 rounded-xl p-1">
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Status
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Application
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Credit Bureau
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Files
                                </Tab>
                                {/* <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Evaluation
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    PM Checklist
                                </Tab> */}

                                {/* Only show this on CRM stage "loanApprovalState" */}
                                {/* {loan?.adminStage !== adminRoles.pm && (
                                    <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                        Risk Evaluation
                                    </Tab>
                                )} */}
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Loan Account
                                </Tab>
                            </Tab.List>

                            <Tab.Panels className="mt-2">
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <StatusIndex loan={loan} />
                                </Tab.Panel>
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <ApplicationIndex loan={loan} />
                                </Tab.Panel>
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <CreditIndex loan={loan} />
                                </Tab.Panel>
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <FilesIndex loan={loan} />
                                </Tab.Panel>
                                {/* <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <EvaluationIndex loan={loan} setLoan={setLoan} setAlert={setAlert} />
                                </Tab.Panel>
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <ChecklistIndex loan={loan} setAlert={setAlert} />
                                </Tab.Panel> */}
                                
                                {/* Show this when loan is in CRM stage */}
                                {/* {loan?.adminStage !== adminRoles.pm && (
                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <RiskIndex loan={loan} setAlert={setAlert} />
                                </Tab.Panel>
                                )} */}
                                {/* Show this when loan is in CRM stage */}

                                <Tab.Panel className={classNames('rounded-xl bg-white p-1')}>
                                    <AccountIndex loan={loan} />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </>
                : 
                <div className='w-full py-2 inline-flex justify-center items-center'>
                    <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />
                </div>
                }
                </section> 
            </main>
        </div>
    )
}

export default ApprovedLoan