import { useState } from 'react'
import { useDispatch } from "react-redux";
import LogoIcon from "../../assets/images/icons/144x.png";
import { navigation } from "../Navigation/navigation";
import { useSelector } from 'react-redux';
import { logout, selectStaff, selectAdminRole } from "../../slices/staffSlice";
import { Link, useLocation } from 'react-router-dom';
import { ShieldCheckIcon } from '@heroicons/react/24/solid';
import { RoleSwitch } from "../../components";
import { reversalAdmins } from '../../utils/constants';
import { hide } from '../../slices/toastSlice';

function Sidebar() {

    const dispatch = useDispatch();
    const staff = useSelector(selectStaff);
    const selectedRole = useSelector(selectAdminRole);
    const roles = staff?.roles;
    const navItems = navigation();
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const [isToggled, setIsToggled] = useState(false);
    // filter out Disbursement route if treasurer not in role of staff
    // const staffLinks = staff?.roles.includes("treasurer") ? navItems?.links : navItems?.links.filter(item => item.name !== "Disbursements");
    const staffLinks = selectedRole === "lo" ? navItems?.loLinks : navItems?.links;

    const handleToggle = () => {
        document.body.classList.toggle("collapsed");
        setIsToggled(!isToggled)
    }

    const handleLogoutRequest = () => {
        dispatch(logout());
        dispatch(hide());
    }

    return (
        <nav className='sidebar-container hidden md:inline-flex isolate overflow-y-auto'>
            <div className='sidebar-top'>
                <div className='logo__wrapper'>
                    <img src={LogoIcon} className='w-auto h-6' alt='CedisPay Micro-Finance Limited' loading='lazy' />
                    <h2 className='font-medium text-lg hide'>CedisPay</h2>
                </div>

                <button className='expand-btn' onClick={handleToggle}>
                    {isToggled ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                    </svg>
                    }
                </button>
            </div>

            <div className='sidebar-links'>
                <ul>
                    {path[1] === "admin" ?
                    navItems?.adminLinks.map((item, index) => ( 
                        <li key={index} className='group'>
                            <Link to={item?.href} title={item?.name} className={path[2] === item?.active.toLowerCase() ? "text-red-500" : ""}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d={item?.icon} />
                                </svg>
                                
                                <span className="link hide">{item?.name}</span>
                            </Link>
                        </li>
                    ))
                    : 
                    staffLinks?.map((item, index) => (
                        <li key={index} className='group'>
                            <Link to={item?.href} title={item?.name} className={path[1] === item?.active.toLowerCase() ? "text-red-500" : ""}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d={item?.icon} />
                                </svg>
                                
                                <span className="link hide">{item?.name}</span>
                                {/* <span className="tooltip-content">Dashboard</span> */}
                            </Link>
                        </li>
                    ))
                    }
                    {/* reversal link */}
                    {(reversalAdmins.includes(staff?.email) && selectedRole !== "lo" && path[1] !== "admin") && (
                    <li className='group'>
                        <Link to="/reversals" title='Reversals' className='hover:cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>
                            <span className="link hide">
                                Reversals
                            </span>
                        </Link>
                    </li>
                    )}
                    {/* reversal link */}

                    <div className="divider"></div>
                    {/* <li className='group'>
                        <a href="http://akrotech-001-site1.1tempurl.com/" target='blank' title='Momo Analyzer'>
                            <DocumentMagnifyingGlassIcon className='w-6 h-6' />
                            <span className="link hide">
                                Momo Analyser
                            </span>
                        </a>
                    </li> */}
                    {staff.isAdmin && (
                        <li className='group'>
                            <Link to={path[1] === "admin" ? "/" : "/admin/home"} title='Admin Portal' className='hover:cursor-pointer'>
                                <ShieldCheckIcon className='w-5 h-5' />
                                <span className="link hide">
                                    {path[1] === "admin" ? "Staff Portal" : "Admin Portal"}
                                </span>
                            </Link>
                        </li>
                    )}

                    {path[1] !== "admin" && (
                        <>
                        <div className="divider"></div>
                        <RoleSwitch id="desktop" roles={roles} isToggled={isToggled} />
                        </>
                    )}
                </ul>
            </div>

            <div className='sidebar-links sidebar-bottom'>
                <div className="divider"></div>

                <div className="sidebar__profile">
                    <div className='flex items-center justify-center gap-2'>
                        {/* <div className="avatar__wrapper">
                            <img className="avatar" src="https://api.multiavatar.com/Binx Bond.png" loading='lazy' alt="Joe Doe" />
                            <div className="online__status" />
                        </div> */}
                        <section className="avatar__name hide">
                            <div className="user-name font-bold">
                                {staff?.otherNames + ' ' + staff?.surname}
                            </div>
                            <div className="email text-xs truncate">
                                {staff?.email}
                            </div>
                        </section>
                    </div>
                    <div className='self-end justify-items-end hide'>
                        <button className='ml-auto transition-all duration-150 ease-in-out p-2 rounded-md bg-slate-50/0 hover:bg-slate-50/10' onClick={handleLogoutRequest}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 rotate-180">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Sidebar