import { useState } from 'react'
import { useDispatch } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import Logo from "../../assets/images/logo.png";
import { Bars3Icon, XMarkIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import { navigation } from "../Navigation/navigation";
import { selectStaff, selectAdminRole, logout } from "../../slices/staffSlice";
import { useSelector } from 'react-redux';
import { Button, RoleSwitch } from "../../components";
import { reversalAdmins } from '../../utils/constants';

function Topbar() {

    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");

    const staff = useSelector(selectStaff);
    const selectedRole = useSelector(selectAdminRole);
    const roles = staff?.roles;
    const navItems = navigation();
    const staffLinks = selectedRole === "lo" ? navItems?.loLinks : navItems?.links;
    const [toggle, setToggle] = useState(false);

    const toggleMobileNav = () => {
        setToggle(!toggle);

        let sidebar = document.getElementById("sidebar-mobile");
        if(sidebar.classList.contains("hidden") || sidebar.classList.contains("hide-mobile-menu")) {
            sidebar.classList.remove("hidden");
            sidebar.classList.remove("hide-mobile-menu");
        } else {
            sidebar.classList.add("hide-mobile-menu");
        }
    }

    const logoutRequest = () => {
        dispatch(logout());
    }

    return (
        <>
        <div className='w-full lg:h-auto shadow-sm py-2 px-2 md:px-8 md:hidden'>
            <div className='section-container p-2 flex items-center justify-between z-10'>
                <Link to={path[1] === "admin" ? `/admin/home` : `/`}>
                    <img src={Logo} className='w-auto h-12' alt='CedisPay Micro-Finance Limited' loading='lazy' />
                </Link>

                {/* mobile nav */}
                {toggle ? 
                <XMarkIcon 
                    className='w-6 h-6 md:hidden cursor-pointer'
                    onClick={toggleMobileNav}
                />
                : 
                <Bars3Icon 
                    className='w-6 h-6 md:hidden cursor-pointer'
                    onClick={toggleMobileNav}
                />
                }
                {/* mobile nav end */}
            </div>
        </div>

        <aside id="sidebar-mobile" aria-label='Sidebar Mobile' className='mobile-menu hidden lg:hidden'>
            <ul className="list-none mt-4">
                {path[1] === "admin" ? (
                    navItems?.adminLinks.map((item, index) => ( 
                        <li key={index}>
                            <Link to={item.href} title={item.name} className="flex flex-row mb-3 space-x-2 px-3 py-2 rounded-md bg-slate-50">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d={item?.icon} />
                                </svg>
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    ))
                ) : (
                    staffLinks?.map((item, index) => (
                        <li key={index}>
                            <Link to={item.href} title={item.name} className='flex flex-row mb-3 space-x-2 px-3 py-2 rounded-md bg-slate-50'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d={item?.icon} />
                                </svg>
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    ))
                )}

                {/* reversal link */}
                {reversalAdmins.includes(staff?.email) && (
                    <li>
                        <Link to="/reversals" title='Reversals' className='hover:cursor-pointer flex flex-row mb-3 space-x-2 px-3 py-2 rounded-md bg-slate-50'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>
                            <span className="link hide">
                                Reversals
                            </span>
                        </Link>
                    </li>
                )}
                {/* reversal link end */}

                <div className="divider bg-slate-200 my-3"></div>

                {/* admin section */}
                {staff.isAdmin && (
                    <li>
                        <Link to={path[1] === "admin" ? "/" : "/admin/home"} title='Admin Portal' className='flex flex-row mb-2 space-x-2 px-3 py-2 rounded-md bg-slate-50'>
                            <ShieldCheckIcon className='w-6 h-6 mr-1' />
                            {path[1] === "admin" ? "Staff Portal" : "Admin Portal"}
                        </Link>
                    </li>
                )}
                {/* admin section end */}

                {path[1] !== "admin" && (
                    <>
                    <div className="divider bg-slate-200 my-3"></div>
                    <RoleSwitch id="mobile" roles={roles} isToggled={false} />
                    </>
                )}

                <Button 
                    btnText={"Logout"}
                    btnClasses={"bg-slate-100 px-5 py-2 rounded-md mt-6"}
                    btnFunction={logoutRequest}
                />
            </ul>
        </aside>
        </>
    )
}

export default Topbar