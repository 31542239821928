import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, FormInput, FormRadio, Toggle } from "../../../components";
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { authStaff } from "../../../utils/constants";
import { fetchRequests } from "../../../Requests/getRequests";
import { show } from '../../../slices/toastSlice';
import { formatName } from '../../../utils/constants';

function Alias({ staff, setStaff }) {

    const selectRoles = [
        {
            value : "lo",
            text : "Loan Officer"
        },
        {
            value : "pm",
            text : "Portfolio Manager"
        },
        {
            value : "hoc",
            text : "Head of Credit"
        },
        {
            value : "crm",
            text : "Credit Risk Manager"
        },
        {
            value : "hor",
            text : "Head of Risk"
        },
    ]

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [first_name, setFirst_name] = useState(staff?.otherNames);
    const [last_name, setLast_name] = useState(staff?.surname);
    const [phone, setPhone] = useState(staff?.phone);
    const [email, setEmail] = useState(staff?.email);
    const [roles, setRoles] = useState(staff?.roles);
    const [isAdmin, setIsAdmin] = useState(staff?.isAdmin);
    const [isTreasurer, setIsTreasurer] = useState(staff?.isTreasurer);
    const [process, setProcess] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let url = `/auth/staff/update`;
        let headers = authStaff(accessToken);
        let data = {
            _id : staff?._id,
            otherNames : formatName(first_name),
            surname : formatName(last_name),
            phone : phone,
            email : email,
            roles : roles,
            isAdmin : isAdmin,
            isTreasurer : isTreasurer
        }

        let res = await fetchRequests(url, "PUT", headers, {}, data);
        setProcess(false);
        
        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        } else {
            dispatch(show({
                type : "success",
                state : true,
                message : `${res?.message}`
            }))
            

            // update staff member
            let staffUrl = `/auth/staff/find`;
            let params = {
                _id : staff?._id
            }

            let staffRes = await fetchRequests(staffUrl, "GET", headers, params, {});
            if(staffRes?.code === 401){
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }
            
            setStaff(staffRes);
        }
    }

    return (
        <div className='space-y-6'>
            <form id='aliasForm' className='w-auto md:w-full'>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    <FormInput 
                        label={`First Name`}
                        name="first_name"
                        id="first_name"
                        type="text"
                        value={first_name}
                        setValue={setFirst_name}
                    />
                    
                    <FormInput 
                        label={`Last Name`}
                        name="last_name"
                        id="last_name"
                        type="text"
                        value={last_name}
                        setValue={setLast_name}
                    />
                    
                    <FormInput 
                        label={`Phone Number`}
                        name="phone"
                        id="phone"
                        type="text"
                        value={phone}
                        setValue={setPhone}
                    />
                    
                    <FormInput 
                        label={`Email Address`}
                        name="email"
                        id="email"
                        type="email"
                        value={email}
                        setValue={setEmail}
                    />

                    <FormRadio 
                        label={"Select Role"}
                        type={"checkbox"}
                        content={selectRoles}
                        value={roles}
                        setValue={setRoles}
                    />

                    <div className='space-y-6'>
                        <Toggle 
                            label="Is an 'Admin'?"
                            value={isAdmin}
                            setValue={setIsAdmin}
                        />

                        <Toggle 
                            label="Is Treasurer?"
                            value={isTreasurer}
                            setValue={setIsTreasurer}
                        />
                    </div>
                </div>

                <div className='inline-flex item-center space-x-3 mt-5'>
                    <Button 
                        btnText={"Submit"}
                        btnClasses="form-button"
                        btnType={"button"}
                        btnFunction={handleRequest}
                        processing={process}
                    />

                    <Link to="/admin/staff" className='cancel-button'>
                        Cancel
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default Alias