import React from 'react'

function FormSelect({ label, name, id, content, value, setValue }) {
    return (
        <div>
            <label htmlFor={name} className='form-label'>{label}</label>
            <select name={name} id={id} required className='form-input' onChange={e => setValue(e.target.value)}>
                {value === "" && <option value={value} hidden>-- select option --</option>}
                {content && content?.map((item, index) => (
                    <option key={index} value={item?.value}>
                        {item?.name ? item?.name : item?.text}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default FormSelect