import React from 'react'
import { FormInput } from "../../components";

function NextOfKin({ nextOfKin, setNextOfKin, nextOfKinPhone, setNextOfKinPhone, nextOfKinAddress, setNextOfKinAddress, nextOfKinRelationship, setNextOfKinRelationship}) {
    return (
        <>
            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6'>
                    Next of Kin Information
                </h3>
            </div>

            <FormInput 
                label="Next of Kin's Full Name"
                name='nextOfKin'
                id="nextOfKin"
                type='text'
                value={nextOfKin}
                setValue={setNextOfKin}
            />

            <FormInput 
                label="Next of Kin's Phone Number"
                name='nextOfKinPhone'
                id="nextOfKinPhone"
                type='text'
                value={nextOfKinPhone}
                setValue={setNextOfKinPhone}
            />

            <FormInput 
                label="Next of Kin's Address"
                name='nextOfKinAddress'
                id="nextOfKinAddress"
                type='text'
                value={nextOfKinAddress}
                setValue={setNextOfKinAddress}
            />

            <FormInput 
                label="Relationship to Next of Kin"
                name='nextOfKinRelationship'
                id="nextOfKinRelationship"
                type='text'
                value={nextOfKinRelationship}
                setValue={setNextOfKinRelationship}
            />
        </>
    )
}

export default NextOfKin