import { getLoanBalance } from '../../../utils/constants';

function State({ loan }) {

    const payments = loan?.ledgerRows.filter(item => item.capital === null || item.capital === undefined);
    const outstandingBalance = (loan?.accountInfo?.totalPayment - getLoanBalance(payments)).toFixed(2);

    if(outstandingBalance > 0){
        return (
            <div className="mt-3 bg-gray-100 px-3 py-2 rounded-md">
                Outstanding Balance of <strong>GHS {outstandingBalance}</strong>, with <strong>{payments?.length}</strong> payments made out of <strong>{loan?.accountInfo?.numPayments}</strong> made.
            </div>
        )
    } 

    return (
        <div className="mt-3 bg-emerald-200 px-3 py-2 rounded-md text-sm">
            Outstanding Balance of <strong>GHS {outstandingBalance}</strong>, with <strong>{payments?.length}</strong> payments made out of <strong>{loan?.accountInfo?.numPayments}</strong> made. 🎉 🎉 <strong>Congratulations, Loan fully paid off</strong> 🎉 🎉
        </div>
    )
}

export default State