import { useEffect, useState } from 'react';
import { Topbar, Sidebar, Loader } from "../../components";
import Filter from './Filter';
import Table from './Table';

function Index() {

    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        document.title = `Payment Transactions - ${process.env.REACT_APP_SITE_NAME}`;
    },[])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>

                <section className='section-container'>
                    
                    <div className='flex items-center justify-between mb-10'>
                        <h3 className='text-lg lg:text-3xl font-medium leading-6 text-display inline-flex items-center'>
                            Payments {loading && <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />}
                        </h3>

                        <Filter 
                            setLoading={setLoading}
                            setTransactions={setTransactions}
                        />
                        
                    </div>

                    <Table transactions={transactions} />
                    
                </section>

            </main>
        </div>
    )
}

export default Index