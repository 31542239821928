import { useState } from 'react';
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { PhotoModal, Loader } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../../slices/staffSlice";
import { authStaff } from "../../../utils/constants";
import { accessFileRequest } from "../../../Requests/getRequests";
import { show } from '../../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function PreviewFile({ file, phone }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fileExt = file.split(".").pop();
    const fileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];

    const [isOpen, setIsOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const closeModal = () => {
        setIsOpen(false);
    }

    const getUrl = async() => {
        setLoader(true);
        let url = `/auth/client/getFile`;

        let headers = authStaff(accessToken);
        let params = {
            name : file,
            phone : phone
        }

        let res = await accessFileRequest(url, headers, params);
        setLoader(false);

        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        const fileName = res.headers['content-disposition'].split('filename=')[1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        const extension = fileName.split('.')[1];
        
        if(fileExtensions.includes(extension)){
            link.click();
            link.remove();
        } else {
            setImageSrc(fileUrl);
            setIsOpen(!isOpen);
        }
    }

    return (
        <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
            <div className="flex w-0 flex-1 items-center">
                <PaperClipIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                />
                <span className="ml-2 w-0 flex-1 truncate">
                    {file}
                </span>
            </div>
            <div className="ml-4 flex-shrink-0">
                <span className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer inline-flex items-center" onClick={getUrl}>
                    {loader && <Loader color="text-gray-700 mr-2" size="3" />} 

                    <span className='text-light-blue cursor-pointer text-right' onClick={getUrl}>
                        {fileExtensions.includes(fileExt) ? 'Download' : 'View'}
                    </span>
                </span>
            </div>
            <div className='hidden'>
                <PhotoModal 
                    imageUrl={imageSrc} 
                    isOpen={isOpen} 
                    closeModal={closeModal} 
                />
            </div>
        </li>
    )
}

export default PreviewFile