import { Link } from "react-router-dom";
import { dateToHumanFormat, loanStatesOld } from "../../utils/constants";
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';

function TableRow({ item, path }) {

    const loanState = item?.adminStage;
    const loanClass = loanState === `pm` ? `bg-indigo-200 text-indigo-600` : 
                        loanState === `hoc` ? `bg-blue-200 text-blue-600` : 
                        loanState === `crm` ? `bg-fuchsia-200 text-fuchsia-600` : 
                        loanState === `hor` ? `bg-cyan-200 text-cyan-600` : 
                        loanState === `true` && item?.state === "approved" ? `bg-teal-200 text-teal-600` : 
                        loanState === `active` && item?.state === "active" ? `bg-emerald-100 text-emerald-700` : 
                        loanState === `completed` && item?.state === "completed" ? `bg-sky-100 text-sky-700` : 
                        `bg-slate-200 text-slate-600`;

    return (
        <tr key={item?._id} className="px-4 py-6 border-b hover:bg-gray-100">
            <td className='px-4 py-3 inline-flex items-center space-x-3'>
                <span>
                    {item?.client?.otherNames} {item?.client?.surname}
                </span>

                {item?.daysInDelinq >= 1 && (
                    <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                        {item?.daysInDelinq} days in arrears
                    </span>
                )}
            </td>
            <td className='px-4 py-3'>
                {item?.phone}
            </td>
            <td className='px-4 py-3'>
                {(path === "active-loans" || path === "completed-loans") ? item?.type : item?.amountReq}
            </td>
            <td className='px-4 py-3'>{item?.amount}</td>
            <td className='px-4 py-3'>{dateToHumanFormat(item?.createdAt)}</td>
            <td className='px-4 py-3'>
                <span className={`rounded-full px-3 py-1.5 text-xs uppercase ${loanClass}`}>
                    {item.adminStage === "true" && item.state === "approved" ? "Facility letter pending" : 
                    item.adminStage === "active" && item.state === "active" ? "Active" :
                    item.adminStage === "completed" && item.state === "completed" ? "Completed" :
                    item.adminStage}
                </span>
            </td>
            <td className='px-4 py-3 flex justify-start'>
                <Link to={item?.state === loanStatesOld.initial ? `/${item?.type.toLowerCase()}/${item._id}` : 
                    item?.state === loanStatesOld.approved ? `/approved/${item?.type.toLowerCase()}/${item._id}` :
                    item?.state === loanStatesOld.active ? `/active/${item._id}` :
                    item?.state === loanStatesOld.completed ? `/completed/${item._id}` :
                    `/rejected/${item._id}`
                }>
                    <EllipsisHorizontalCircleIcon 
                        className="w-6 h-6 hover:scale-125 transition-all duration-75 ease-in-out" 
                    />
                </Link>
            </td> 
        </tr>
    )
}

export default TableRow