import { useState } from "react";
import { LoansTableRow, Pagination } from "../../components";
import { useLocation } from "react-router-dom";

function Table({ loans }) {

    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/")[1];

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = loans?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(loans?.length / recordsPerPage);
    
    return (
        <>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <table className='min-w-full'>
                    <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50'>
                        <tr>
                            <th scope="col" className='px-4 py-3'>Full Name</th>
                            <th scope="col" className='px-4 py-3'>Phone No.</th>
                            <th scope="col" className='px-4 py-3'>{(path === "active-loans" || path === "completed-loans") ? `Type` : `Amount Req.`}</th>
                            <th scope="col" className='px-4 py-3'>Amount Elig.</th>
                            <th scope="col" className='px-4 py-3'>Applied on</th>
                            <th scope="col" className='px-4 py-3'>Stage</th>
                            <th scope="col" className='px-4 py-3'>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {currentRecords && currentRecords?.length > 0 ? (
                            currentRecords?.map(item => (
                                <LoansTableRow 
                                    key={item?._id}
                                    item={item}
                                    path={path}
                                />
                            ))
                        ) : (
                            <tr className="px-4 py-6 border-b hover:bg-gray-100">
                                <td colSpan="7" className='px-4 py-3 text-center font-medium'>
                                    No loan applications 
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {(currentRecords && currentRecords?.length > 0) && 
                    <Pagination 
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        length={loans?.length}
                    />
                }
            </div>
        </div>
        </>
    )
}

export default Table